import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC20TubyFtRCIsP4SkKkvx9OdOOFIUsY9E",
  authDomain: "banza-app.firebaseapp.com",
  projectId: "banza-app",
  storageBucket: "banza-app.appspot.com",
  messagingSenderId: "678739947265",
  appId: "1:678739947265:web:e6f0c6f1f4e9a93e9a7500",
  measurementId: "G-1X8HZK5652",
};

const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);
const channel = new BroadcastChannel("firebaseBackgroundNotification");
export const getFirebaseToken = () =>
  getToken(messaging, {
    vapidKey:
      "BCiC5L2ygpawsbqTThdPAhHHJ6wjdPfT_GYQBllwOUotrGebDnNSj24lRUj4w6T26GVBmcXKgCQrtV7ic1OS_98",
  }).catch(() => null);

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));

export const requestPermission = () => {
  Notification.requestPermission().then((permission) => {
    // if (permission === "granted") {
    // }
  });
};

export const disableNotifications = () => {
  deleteToken(messaging).catch(() => ({}));
};

export const handleBackgroundNotifications = (callback?: () => void) => {
  channel.onmessage = function (e) {
    if (callback) callback();
  };
};

export const stopHandlingBackgroundNotifications = () => {
  channel.close();
};
