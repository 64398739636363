export const USER_ALREADY_EXIST = "ErrorCode.Auth_Email_Already_Exists";
export const INVALID_TOKEN = "ErrorCode.Auth_Invalid_Token";
export const USER_ACCOUNT_NOT_FOUND = "ErrorCode.User_Account_Not_Found";
export const USER_UNDER_AGE = "ErrorCode.User_is_minor";
export const ONBOARDING_HASNT_BEEN_INITED =
  "ErrorCode.Onboard_Account_Not_Found";
export const ALREADY_VALIDATED = "ErrorCode.Already_Validated"; // This Image has already been validated
export const DNI_CODE_NOTFOUND = "ErrorCode.DNI_Code_NotFound"; // Could not find DNI Code
export const DNI_NO_FACE_DETECTED = "ErrorCode.DNI_No_Face_Detected"; // DNI no face detected
export const DNI_MULTIPLE_FACES = "ErrorCode.DNI_Multiple_Faces"; // DNI multiple faces found
export const DNI_MULTIPLE_CODES = "ErrorCode.DNI_Multiple_Codes"; // DNI multiple codes found
export const DNI_INVALID_PDF417 = "ErrorCode.DNI_Invalid_PDF417"; // Cannot validate PDF417
export const DNI_INVALID_MRZ = "ErrorCode.DNI_Invalid_MRZ"; // Cannot validate MRZ_Code
export const DNI_EXPIRED = "ErrorCode.DNI_Expired"; // DNI has expired. Due date:
export const DNI_COULD_NOT_BE_UPLOADED = "ErrorCode.DNI_Could_Not_Be_Uploaded"; // DNI Could Not be Uploaded
export const INVALID_BASE64 = "ErrorCode.Invalid_Base64"; // Invalid Base64
export const INVALID_FORMAT = "ErrorCode.Invalid_Format"; // Invalid format {formato}, expected
export const SELFIE_INVALID = "ErrorCode.Selfie_Invalid"; // Selfie Invalid
export const SELFIE_NO_FACE_DETECTED = "ErrorCode.Selfie_No_Face_Detected"; // Selfie No Face Detected
export const SELFIE_MULTIPLE_FACES = "ErrorCode.Selfie_Multiple_Faces"; // Selfie multiple faces found
export const SELFIE_NO_GESTURE = "ErrorCode.Selfie_No_Gesture"; // No selfie gesture
export const SIGNATURE_UPLOAD_ERROR = "ErrorCode.Signature_Upload_Error"; // No se pudo cargar la firma
export const TERMS_NOT_ACCEPTED = "ErrorCode.Terms_not_accepted"; // No se aceptaron los términos y condiciones
export const USER_ALREADY_EXISTS = "ErrorCode.User_Already_Exists"; // User already exists
export const USER_ALREADY_ONBOARDED = "ErrorCode.User_Already_Onboarded"; // The user is already onboarded.
export const USER_DOESNT_EXISTS = "ErrorCode.User_Doesnt_Exists"; // User doesn't exists
export const USER_INVALID_AGE = "ErrorCode.User_Invalid_Age"; // User invalid age. Current Age: {current}, Minimum Age:
export const USER_INVALID_NUMBER_OF_TRIES =
  "ErrorCode.User_Invalid_Number_Of_Tries"; // User Update max retries exceeded, current: {current}, app_max
export const USER_MAX_RETRIES_EXCEEDED_DNI_BACK =
  "ErrorCode.User_Max_Retries_Exceeded_Dni_Back"; // User DNI Back max retries exceeded, current: {current}, app_max
export const USER_MAX_RETRIES_EXCEEDED_DNI_FRONT =
  "ErrorCode.User_Max_Retries_Exceeded_Dni_Front"; // User DNI Front max retries exceeded, current: {current}, app_max
export const USER_MAX_RETRIES_EXCEEDED_SELFIE =
  "ErrorCode.User_Max_Retries_Exceeded_Selfie"; // User Selfie max retries exceeded, current: {current}, app_max
export const SIGNATURE_NOT_VALIDATED = "ErrorCode.User_Not_Validated"; // Not validated user
export const SIGNATURE_ALREADY_FINALIZED = "ErrorCode.User_Already_Finalized"; // User already finalized
export const SIGNATURE_INCOMPLETE_INFO = "ErrorCode.User_Incomplete_Info"; // The user info is incomplete. Missing:

export const OUTSIDE_MARKET_HOUR = "ErrorCode.Outside_Market_Hour"; // The user info is incomplete. Missing:

export const RESOLUTION_962_CNV = "ErrorCode.Resolucion_962_CNV"; // The user info is incomplete. Missing:

export const CC_OVERDRAFT = "ErrorCode.CC_Overdraft_Error";
export const BANK_NOT_FOUND = "ErrorCode.No_Cod_Institucion_Ban_Found";
export const CURRENCY_MISMATCH = "ErrorCode.Currency_Mismatch";

export const INVALID_TRANSFER_RECEIVER = "ErrorCode.Bank_account_doesnt_exist"; // The user info is incomplete. Missing:
export const INVALID_CVU_RECEIVER = "ErrorCode.No_Bank_Account_Found"; // The user info is incomplete. Missing:
export const INVALID_CBU_BLOCKED = "ErrorCode.CBU_Blocked";// CBU BLOCKED
