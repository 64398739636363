import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import {
  getTimestamp,
  getIsAuthenticated,
  getRefreshToken,
  removeAuthdata,
  setTimestamp,
  setRefreshToken,
  setToken,
  setUserStatus,
  getUserStatus,
  getToken,
  setUserUuidStorage,
  getUserUuidStorage,
  removeUserUuidStorage,
} from "utility/storage/auth";
import { USER_ALREADY_EXIST } from "utility/CommonConstants";
import { axiosDataInstance } from "api/axiosInstances";
import { unregisterUserDevice } from "store/notification/notificationSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "utility/helpers/firebaseHelper";
import * as amplitude from '@amplitude/analytics-browser';

export const initialState: any = {
  userState: null,
  temporalToken: undefined,
  email: undefined,
  userData: null,
  userInfo: null,
  isAuthenticated: null,
  isLoading: false,
  errorMessage: undefined,
  errorCode: undefined,
  maintenance: null,
  showModal: false,
  showShutdownScreen: true
  // itsOk:errorMessage|errorCode;
  // errorVerifyEmail:undefined,
  // errorVerifyCode:undefined,
  // errorCodeResend:undefined,
  // errorChangePassword:undefined,
  // errorRecoveryEmail:undefined,
  // errorRecoveryResend:undefined,
  // errorRecoveryVerify:undefined,
};

export const login = createAsyncThunk(
  "login",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.login(argument);
      if (res.status === 200) {
        // const auxRes = await axiosDataInstance.get(`/market/account/`);
        // console.log("auxRes", auxRes);
        logEvent(analytics, "login")
        amplitude.setUserId(res.data.user.uuid);
        amplitude.track('Log In', {login_method: 'password'})
        setUserStatus(res.data.nextStep);
        setToken(res.data.authenticationResult.idToken);
        setRefreshToken(res.data.authenticationResult.refreshToken);
        setTimestamp(
          (
            new Date().getTime() +
            res.data.authenticationResult.expiresIn * 1000
          ).toString()
        );
        localStorage.setItem("isAuthenticated", "true");
        if (argument.email === "dev_test@banza.com.ar") {
          setUserStatus("OPERATE");
        }
      }
      await setUserUuidStorage(res.data.user.uuid)

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const isLoggedIn = createAsyncThunk(
  "isLoggedIn",
  async (argument: any, thunkAPI: any) => {
    const now = new Date();
    const authUserUuid = await getUserUuidStorage();
    if(authUserUuid){
      amplitude.setUserId(authUserUuid)
  }
    if (now.getTime() >= Number(getTimestamp())) {
      removeAuthdata();
      localStorage.setItem("isAuthenticated", "false");
      return false;
    }
    return getIsAuthenticated() ?? false;
  }
);

export const refreshToken = createAsyncThunk(
  "refreshToken",
  async (argument: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.refreshToken({
        refresh_token: argument,
      });
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (argument: boolean | null | undefined, thunkAPI: any) => {
    try {
      if (argument !== false) {
        try {
          await thunkAPI.dispatch(unregisterUserDevice());
        } catch {
          /* */
        }
      }
      await removeUserUuidStorage();
      removeAuthdata();
      clearAuthStore();
    } catch (error) {
      /* */
    }
  }
);

export const signUp = createAsyncThunk(
  "signUp",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.signUp(argument);
      return res;
    } catch (e: any) {
      console.log(e);
    }
  }
);
export const emailVerify = createAsyncThunk(
  "emailVerify",
  async (email: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.emailVerify(email);
      amplitude.track('Email Registered')
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const codeResend = createAsyncThunk(
  "codeResend",
  async (email: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.codeResend(email);
      amplitude.track("New Email Code Requested")
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const codeVerify = createAsyncThunk(
  "codeVerify",
  async (code: any, thunkAPI: any) => {
    try {
      const client = process.env.REACT_APP_STORAGE_CLIENT_KEY!;
      const res = await thunkAPI.extra.auth.codeVerify({ ...code, client });
      if (res.status === 200) {
        setToken(res.data.token);
        setTimestamp(new Date(res.data.codeExpiration).getTime().toString());
        // localStorage.setItem("isAuthenticated", "true");
      }
      if(res.data && res.data.uuid){
        amplitude.setUserId(res.data.uuid)
        amplitude.track('Email Validated')
    }

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      console.log("err", err.response);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const recoverPasswordRequest = createAsyncThunk(
  "recoverPasswordRequest",
  async (email: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.recoverPasswordRequest(email);
      if (res.status === 200) res.email = email;
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const recoveryResend = createAsyncThunk(
  "recoveryResend",
  async (code: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.recoverPasswordRequest(
        thunkAPI.getState().auth.email
      );
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const recoverPasswordVerify = createAsyncThunk(
  "recoverPasswordVerify",
  async (data: any, thunkAPI: any) => {
    try {
      const client = process.env.REACT_APP_STORAGE_CLIENT_KEY!;
      const res = await thunkAPI.extra.auth.recoverPasswordVerify({
        code: data.code,
        client,
        email: data.email,
      });

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const recoverPasswordReset = createAsyncThunk(
  "recoverPasswordReset",
  async (password: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.recoverPasswordReset({
        email: thunkAPI.getState().auth.email,
        token: thunkAPI.getState().auth.token,
        password,
      });

      if (res.status === 200) {
        setUserStatus(res.data.nextStep);
        setToken(res.data.authenticationResult.idToken);
        setTimestamp(
          (
            new Date().getTime() + res.data.authenticationResult.expiresIn
          ).toString()
        );
        localStorage.setItem("isAuthenticated", "true");
      }
      // Amplitude
      amplitude.track('Password Created')
      //
      const check = getToken();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAppMaintenance = createAsyncThunk(
  "get-App-Maintenance",
  async (argument: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.auth.getAppMaintenance();
      const data = res.data;
      return data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    // ------------- //
    cleanErrorState: (state, action) => {
      if (action.payload) {
        state.email = undefined;
      }
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    openModal: state => {
      const modalShown = sessionStorage.getItem("modalShown");
      if (modalShown === "false" || !modalShown) {
        state.showModal = true;
        sessionStorage.setItem("modalShown", "true");
      }
    },
    closeModal: state => {
      state.showModal = false;
      sessionStorage.removeItem("modalShown");
    },
    // forceOperate: (state) => {
    //   state.userState = "OPERATE";
    // },
    forceInvestLogin: (state) => {
      setToken("hardcoded");
      setRefreshToken("hardcoded");
      setUserStatus("OPERATE");
      setTimestamp(new Date().getTime().toString() + 5000000);
      localStorage.setItem("isAuthenticated", "true");
      state.isAuthenticated = true;
      state.isLoading = false;
      state.errorMessage = null;
    },
    getUserState: (state) => {
      state.userState = getUserStatus();
    },
    clearAuthStore: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    // Login
    [login.fulfilled.type]: (state, action) => {
      if (action.payload.data.user.email === "dev_test@banza.com.ar") {
        state.userState = "OPERATE";
      } else {
        state.userState = action.payload.data.nextStep;
      }
      state.userInfo = action.payload.data.user;
      if (state.userState === "OPERATE") {
        localStorage.setItem(
          "userInfo",
          JSON.stringify(action.payload.data.user)
        ); //Verificar despues si userData es el mismo objeto q este q cree
      }
      state.isAuthenticated = true;
      state.isLoading = false;
      state.errorMessage = null;
    },
    [login.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = undefined;
    },
    [login.rejected.type]: (state, action) => {
      state.userData = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      localStorage.setItem("isAuthenticated", "false");
      state.errorMessage = action.payload?.message;
    },
    //logout
    [logout.fulfilled.type]: (state, action) => {
      state.authUser = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      localStorage.setItem("isAuthenticated", "false");
    },
    [logout.pending.type]: (state, action) => {
      state.isLoading = true;
    },
    [logout.rejected.type]: (state, action) => {
      state.isLoading = false;
    },
    // signUp
    [signUp.fulfilled.type]: (state, action) => {
      localStorage.setItem(
        "userData",
        JSON.stringify(action.payload.data.userData)
      );
      state.userData = action.payload.data;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.errorMessage = null;
    },
    [signUp.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = undefined;
    },
    [signUp.rejected.type]: (state, action) => {
      state.userData = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.errorMessage = action.payload?.message;
    },
    // refreshToken
    [refreshToken.fulfilled.type]: (state, action) => {
      localStorage.setItem(
        "userData",
        JSON.stringify(action.payload.data.userData)
      );
      setToken(action.payload.data.access_token);
      setRefreshToken(action.payload.data.refresh_token);
      state.userData = action.payload.data;
      state.isLoading = false;
      state.errorMessage = "";
    },
    [refreshToken.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = "";
    },
    [refreshToken.rejected.type]: (state, action) => {
      state.userData = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.errorMessage = action.payload?.message;
      removeAuthdata();
      window.location.href = "/";
    },
    //isLoggedIn
    [isLoggedIn.fulfilled.type]: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    [isLoggedIn.pending.type]: (state, action) => {
      // state.isAuthenticated=action.payload;
    },
    [isLoggedIn.rejected.type]: (state, action) => {
      state.isAuthenticated = false;
    },
    //emailVerify
    [emailVerify.fulfilled.type]: (state, action) => {
      // state.itsOk=true;
      state.errorMessage = null;
      state.errorCode = null;
      state.isLoading = false;
    },
    [emailVerify.pending.type]: (state, action) => {
      state.errorMessage = undefined;
      state.isLoading = true;
    },
    [emailVerify.rejected.type]: (state, action) => {
      state.errorCode = action?.payload?.errorCode;
      //se comenta error ya que se renderiza antes de cambiar de page
      // state.errorMessage=action?.payload?.message??"Ocurrió un error inesperado al validar email";
      if (action?.payload?.errorCode != USER_ALREADY_EXIST)
        state.errorMessage =
          "Ocurrió algo inesperado, intenta nuevamente mas tarde";
      state.isLoading = false;
    },
    //codeVerify
    [codeVerify.fulfilled.type]: (state, action) => {
      state.token = action?.payload?.data?.token;
      state.email = action?.payload?.data?.email;
      state.errorMessage = null;
      state.errorCode = null;
      state.isLoading = false;
    },
    [codeVerify.pending.type]: (state, action) => {
      state.errorMessage = undefined;
      state.isLoading = true;
    },
    [codeVerify.rejected.type]: (state, action) => {
      state.errorMessage = action?.payload?.message ?? "Código incorrecto";
      state.isLoading = false;
    },
    //codeResend
    [codeResend.fulfilled.type]: (state, action) => {
      state.errorMessage = null;
      state.isLoading = false;
    },
    [codeResend.pending.type]: (state, action) => {
      state.errorMessage = undefined;
      state.isLoading = true;
    },
    [codeResend.rejected.type]: (state, action) => {
      state.errorMessage =
        action?.payload?.message ??
        "Ocurrió algo inesperado, no se pudo reenviar código";
      state.isLoading = false;
    },
    //recoverPasswordReset
    [recoverPasswordReset.fulfilled.type]: (state, action) => {
      state.userState = action.payload.data.nextStep;
      state.errorCode = null;
      state.errorMessage = null;
      state.isLoading = false;
    },
    [recoverPasswordReset.pending.type]: (state, action) => {
      state.errorMessage = undefined;
      state.isLoading = true;
    },
    [recoverPasswordReset.rejected.type]: (state, action) => {
      state.errorMessage =
        action?.payload?.message ??
        "Ocurrió algo inesperado al cambiar la contraseña";
      state.isLoading = false;
    },
    //recoverPasswordRequest
    [recoverPasswordRequest.fulfilled.type]: (state, action) => {
      (state.email = action?.payload?.data?.email), (state.errorCode = null);
      state.errorMessage = null;
      state.isLoading = false;
    },
    [recoverPasswordRequest.pending.type]: (state, action) => {
      state.errorMessage = undefined;
      state.isLoading = true;
    },
    [recoverPasswordRequest.rejected.type]: (state, action) => {
      state.errorMessage =
        action?.payload?.message ??
        "Ocurrió algo inesperado al enviar el email";
      state.isLoading = false;
    },
    //recoverPasswordReset
    [recoveryResend.fulfilled.type]: (state, action) => {
      state.errorCode = null;
      state.errorMessage = null;
      state.isLoading = false;
    },
    [recoveryResend.pending.type]: (state, action) => {
      state.errorMessage = undefined;
      state.isLoading = true;
    },
    [recoveryResend.rejected.type]: (state, action) => {
      state.errorMessage =
        action?.payload?.message ??
        "Ocurrió algo inesperado al reenviar el email";
      state.isLoading = false;
    },
    //recoverPasswordVerify
    [recoverPasswordVerify.fulfilled.type]: (state, action) => {
      state.token = action?.payload?.data?.token;
      state.errorCode = null;
      state.errorMessage = null;
      state.isLoading = false;
    },
    [recoverPasswordVerify.pending.type]: (state, action) => {
      state.errorMessage = undefined;
      state.errorCode = undefined;
      state.isLoading = true;
    },
    [recoverPasswordVerify.rejected.type]: (state, action) => {
      state.errorMessage =
        action?.payload?.message ??
        "Ocurrió algo inesperado al cambiar la contraseña";
      state.errorRecoveryVerify = action?.payload?.errorCode;
      state.isLoading = false;
    },
    [getAppMaintenance.fulfilled.type]: (state, action) => {
      state.maintenance = action?.payload?.maintenance;
      state.message = action?.payload?.message;
      state.isLoading = false;
      state.errorMessage = null;
    },
    [getAppMaintenance.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getAppMaintenance.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action?.payload?.errorCode;
    },
  },
});
export const {
  cleanErrorState,
  // forceOperate,
  getUserState,
  forceInvestLogin,
  clearAuthStore,
  openModal,
  closeModal
} = authSlice.actions;
export const authSelector = (state: RootState) => state.auth;
export default authSlice.reducer;
