import {
  bindActionCreators,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  removeSelectedContact,
  setSelectedContact,
  setSelectedWithdrawalsAccount,
} from "utility/storage/transfer";
import type { RootState } from "../store";
import { TransferState } from "./transferTypes";

export const initialState: TransferState = {
  account: undefined,
  validatedReceiver: undefined,
  transferAccount: undefined,
  contacts: undefined,
  concepts: undefined,
  movements: undefined,
  movementDetail: undefined,
  transaction: undefined,

  selectedContact: undefined,

  withdrawalsAccount: undefined,

  isLoading: false,
  errorMessage: undefined,
  errorCode: undefined,

  pendingPopUpMessage:false,
};

export const getTransferAccount = createAsyncThunk(
  "get-transfer-account",
  async (argument: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.getTransferAccount();

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getBankAccount = createAsyncThunk(
  "get-bank-account",
  async (transferReceiverId: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.getBankAccount(
        transferReceiverId
      );

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getBankAccountToWithdrawals = createAsyncThunk(
  "get-bank-account-withdrawals",
  async (originAccountId: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.getBankAccount(originAccountId);

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const transfer = createAsyncThunk(
  "transfer",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.transfer(argument);

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getContacts = createAsyncThunk(
  "get-contacts",
  async (argument: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.getContacts();

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateContact = createAsyncThunk(
  "update-contact",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.updateContact(argument);
      if(res.status===200){
        selectContact(res.data);
      }
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateMyAlias = createAsyncThunk(
  "update-my-alias",
  async (newAlias:string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.updateMyAlias(newAlias);

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteContact = createAsyncThunk(
  "delete-contact",
  async (contactId: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.deleteContact(
        contactId
      );

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getConcepts = createAsyncThunk(
  "get-concepts",
  async (argument: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.getConcepts();

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const postConcept = createAsyncThunk(
  "post-concept",
  async (argument: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.postConcept({ name: argument });

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getMovementsByPeriod = createAsyncThunk(
  "get-movements",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.getMovementsByPeriod(argument);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getMovementDetail = createAsyncThunk(
  "get-movement-details",
  async (transactionId: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.transfer.getMovementDetail(
        transactionId
      );
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    // ------------- //
    cleanErrorState: (state) => {
      state.errorCode = undefined;
      state.errorMessage = "";
    },
    selectContact: (state, action) => {
      state.selectedContact = action.payload;
      setSelectedContact(action.payload);
    },
    selectWithdrawalsAccount: (state, action) => {
      state.selectedContact = action.payload;
      setSelectedWithdrawalsAccount(action.payload);
    },
    cleanSelectedContact: (state) => {
      state.selectedContact = undefined;
      removeSelectedContact();
    },
    setPendingPopUpMessage: (state,action) => {
      state.pendingPopUpMessage=action.payload;
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    [getTransferAccount.fulfilled.type]: (state, action) => {
      state.transferAccount = action.payload.data;
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getTransferAccount.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      state.errorCode = undefined;
    },
    [getTransferAccount.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [getBankAccount.fulfilled.type]: (state, action) => {
      state.selectedContact = {
        ...action?.payload?.data,
      };
      state.selectedContact[
        `${action?.payload?.data?.taxDocType?.toLowerCase()}`
      ] = action?.payload?.data?.taxDocNumber;
      state.selectedContact[
        `${action?.payload?.data?.accountType?.toLowerCase()}`
      ] = action?.payload?.data?.accountValue;

      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getBankAccount.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      state.errorCode = undefined;
    },
    [getBankAccount.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [getBankAccountToWithdrawals.fulfilled.type]: (state, action) => {
      state.withdrawalsAccount = {
        ...action?.payload?.data,
      };

      state.withdrawalsAccount[
        `${action?.payload?.data?.taxDocType?.toLowerCase()}`
      ] = action?.payload?.data?.taxDocNumber;
      state.withdrawalsAccount[
        `${action?.payload?.data?.accountType?.toLowerCase()}`
      ] = action?.payload?.data?.accountValue;
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getBankAccountToWithdrawals.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      state.errorCode = undefined;
    },
    [getBankAccountToWithdrawals.rejected.type]: (state, action) => {
      const hardCode = {
        name: "TODO ROSMARIE",
        bank: "BANCO VOII S.A.",
        taxDocType: "CUIT",
        taxDocNumber: "27394362285",
        accountType: "CVU",
        accountValue: "0000393109349426997158",
        alias: "TROSMAR.BANZA.PLF",
      };
      state.withdrawalsAccount = {
        ...hardCode,
      };

      state.withdrawalsAccount[`${hardCode?.taxDocType?.toLowerCase()}`] =
        action?.payload?.data?.taxDocNumber;
      state.withdrawalsAccount[`${hardCode?.accountType?.toLowerCase()}`] =
        hardCode.accountValue;
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [transfer.fulfilled.type]: (state, action) => {

      state.transaction = action.payload?.data;
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [transfer.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      state.errorCode = undefined;
    },
    [transfer.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },

    [getContacts.fulfilled.type]: (state, action) => {
      state.contacts = action.payload?.data.contacts;
      // state.contacts=[];
      // state.contacts = [
      //   {
      //     contactId: 17,
      //     name: "Aaas Perez",
      //     username: "",
      //     photo: null,
      //     cuit: "27394362308",
      //     cvu: null,
      //     cbu: "12312443141514",
      //     alias: "ALIAS.POM",
      //     nickname: null,
      //     internal: false,
      //     bank: "Banco Santander",
      //     userIsOwner: false,
      //     lastUpdate: "2022-08-16 15:33:42",
      //   },
      //   {
      //     contactId: 18,
      //     name: "Ana",
      //     username: "anamu",
      //     photo: "PHOTO_URL",
      //     cuit: "30233214571",
      //     cvu: "0000393108697450493117",
      //     cbu: null,
      //     alias: "TROSMAR.BANZA.TB5",
      //     nickname: null,
      //     internal: true,
      //     bank: "Banza",
      //     userIsOwner: false,
      //     lastUpdate: "2022-08-16 15:53:18",
      //   },
      //   {
      //     contactId: 20,
      //     name: "Ana",
      //     username: "anamu",
      //     photo: "PHOTO_URL",
      //     cuit: "30233214571",
      //     cvu: "0000393109432506628955",
      //     cbu: null,
      //     alias: "DPOMELO.BANZA.A1G",
      //     nickname: "SoyUnNickname",
      //     internal: true,
      //     bank: "Banza",
      //     userIsOwner: false,
      //     lastUpdate: "2022-08-18 20:14:19",
      //   },
      //   {
      //     contactId: 19,
      //     name: "TODO ROSMARIE",
      //     username: "",
      //     photo: null,
      //     cuit: "27394362285",
      //     cvu: "0000393109349426997158",
      //     cbu: null,
      //     alias: "TROSMAR.BANZA.PLF",
      //     nickname: "Rose2",
      //     internal: false,
      //     bank: "BANCO VOII S.A.",
      //     userIsOwner: false,
      //     lastUpdate: "2022-08-22 00:13:55",
      //   },
      // ];

      state.isLoading = false;
      state.errorCode = null;
      state.errorMessage = null;
    },
    [getContacts.pending.type]: (state, action) => {
      state.contacts = undefined;
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getContacts.rejected.type]: (state, action) => {
      state.contacts = undefined;
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [updateContact.fulfilled.type]: (state, action) => {
      state.selectedContact = action.payload?.data;
      selectContact(action.payload?.data);
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [updateContact.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [updateContact.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
      state.errorCode = "action.payload.errorCode";
    },
    [updateMyAlias.fulfilled.type]: (state, action) => {
      //modificar
      // state.selectedContact = action.payload.data;
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [updateMyAlias.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [updateMyAlias.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
      state.errorCode = action.payload?.errorCode;
    },
    [deleteContact.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.errorCode = null;
      state.errorMessage = null;
    },
    [deleteContact.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorCode = undefined;
      state.errorMessage = undefined;
    },
    [deleteContact.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [getConcepts.fulfilled.type]: (state, action) => {
      state.concepts = action?.payload?.data?.categories;
      state.isLoading = false;
      state.errorCode = null;
      state.errorMessage = null;
    },
    [getConcepts.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getConcepts.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [getMovementsByPeriod.fulfilled.type]: (state, action) => {
      state.movements = action?.payload?.data?.contactTransfers;
      state.isLoading = false;
      state.errorCode = null;
      state.errorMessage = null;
    },
    [getMovementsByPeriod.pending.type]: (state, action) => {
      state.movements = undefined;
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getMovementsByPeriod.rejected.type]: (state, action) => {
      state.movements = [];
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [getMovementDetail.fulfilled.type]: (state, action) => {
      state.movementDetail = action?.payload?.data;
      state.isLoading = false;
      state.errorCode = null;
      state.errorMessage = null;
    },
    [getMovementDetail.pending.type]: (state, action) => {
      state.movementDetail = undefined;
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getMovementDetail.rejected.type]: (state, action) => {
      state.movementDetail = {};
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [postConcept.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.errorCode = null;
      state.errorMessage = null;
    },
    [postConcept.pending.type]: (state, action) => {
      state.concepts=undefined;
      state.isLoading = true;
      state.errorMessage = null;
    },
    [postConcept.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
  },
});

export const {
  cleanErrorState,
  selectContact,
  selectWithdrawalsAccount,
  cleanSelectedContact,
  setPendingPopUpMessage
} = transferSlice.actions;
export const transferSelector = (state: RootState) => state.transfer;
export default transferSlice.reducer;
