import {
  bindActionCreators,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { DNI_EXPIRED, USER_INVALID_AGE } from "utility/CommonConstants";
import { getToken, setUserStatus } from "utility/storage/auth";
import type { RootState } from "../store";
import {
  OnboardingState,
  StepContent,
  IdentityErrorTypes,
} from "./onboardingTypes";
import * as amplitude from '@amplitude/analytics-browser';

export const initialState: OnboardingState = {
  prevUserInfo: null,
  onboardingStep: 1,
  testQuestion: 1,
  identityValidationError: null,
  testSteps: [],
  testStepContent: { question: null, category: null, answers: [] },
  answerArray: Array(5).fill(0),
  userInfo: null,
  resultTest: null,
  errorMessage: null,
  errorCode: undefined,
  errorCodeGetUserInfo: undefined,
  isLoading: false,
  selfieGesture: null,
  photoIDCardFrontValid: null,
  photoIDCardBackValid: null,
  photoSelfieValid: null,
  photoIDCardFrontValidError: null,
  photoIDCardBackValidError: null,
  photoSelfieValidError: null,
  waitingValidation: false,
  needPersistProtectedState:false,
  zipInfo: null,

};
export const begin = createAsyncThunk(
  "begin",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.begin();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const nickname = createAsyncThunk(
  "nickname",
  async (nickname: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.nickname(nickname);
      const baseEvent = {
        event_type:  'Nickname Completed',
        user_properties: { nickname: nickname }
    }

    amplitude.track(baseEvent)
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const uploadDni = createAsyncThunk(
  "uploadDni",
  async (dni: any, thunkAPI: any) => {
    try {
      const frontValid = thunkAPI.getState().onboarding.photoIDCardFrontValid;
      const backValid = thunkAPI.getState().onboarding.photoIDCardBackValid;
      const promises = [
        !frontValid || frontValid == null
          ? thunkAPI.extra.onboarding.uploadDniFront({ DNIFront: dni.front }).then(() => {
            const baseEvent = {
              event_type: 'Document Uploaded',
              event_properties: { document_type: 'DNI_FRONT' },
            };
            amplitude.track(baseEvent);
          })
          : null,
        !backValid || backValid == null
          ? thunkAPI.extra.onboarding.uploadDniBack({ DNIBack: dni.back }).then(() => {
            const baseEvent = {
              event_type: 'Document Uploaded',
              event_properties: { document_type: 'DNI_BACK' },
            };
            amplitude.track(baseEvent);
          })
          : null,
      ];
      const res = Promise.all(promises);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const selfieGesture = createAsyncThunk(
  "selfieGesture",
  async (argument: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.selfieGesture();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const uploadSelfie = createAsyncThunk(
  "uploadSelfie",
  async (selfie: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.uploadSelfie(selfie);
      const baseEvent = {
        event_type:  'Document Uploaded',
        event_properties: { document_type: 'SELFIE'},
    }
    amplitude.track(baseEvent)
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const uploadSignature = createAsyncThunk(
  "uploadSignature",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.uploadSignature(data);
      amplitude.track('Signature Uploaded');
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (isInitialRequest: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.getUserInfo();
      setUserStatus(res.data.nextStep);
      res.isInitialRequest = isInitialRequest;
      if (!isInitialRequest) {
        // res.data.dniFrontValidationError=DNI_EXPIRED;
        // res.data.dniFrontValidationError=USER_INVALID_AGE;
        // res.data.dniBackValidationError=USER_INVALID_AGE;
      }
      const backDNItries = res.data.dniBackTries === 3 ?? false
      const frontDNItries = res.data.dniFrontTries === 3 ?? false
      const selfieTries = res.data.selfieTries === 3 ?? false
        if(backDNItries || frontDNItries || selfieTries){
            amplitude.track('Document Validation Pending', {
                 document_type: [ 
                     backDNItries ? 'DNI_BACK' : null,
                     frontDNItries ? 'DNI_FRONT' : null, 
                     selfieTries ? 'SELFIE' : null
                 ]
             })
        }
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getUserInfoForThePolling = createAsyncThunk(
  "getUserInfoForThePolling",
  async (arg: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.getUserInfo();
      setUserStatus(res.data.nextStep);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const uploadUserInfo = createAsyncThunk(
  "uploadUserInfo",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.uploadUserInfo(
        thunkAPI.getState().onboarding.userInfo
      );
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const inversorQuestions = createAsyncThunk(
  "inversorQuestions",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.inversorQuestions({
        experience: thunkAPI.getState().onboarding.answerArray[0],
        information: thunkAPI.getState().onboarding.answerArray[1],
        savings: thunkAPI.getState().onboarding.answerArray[2],
        risk: thunkAPI.getState().onboarding.answerArray[3],
        horizon: thunkAPI.getState().onboarding.answerArray[4],
      });
      setUserStatus(res.data.nextStep);
      // Amplitude Evento + User properties
      const baseEvent = {
        event_type:  'Investment Profile Completed',
        user_properties: { investment_profile: res.data.profile }
    }
    amplitude.track(baseEvent)
    //
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const finalize = createAsyncThunk(
  "finalize",
  async (data: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.finalize();
      setUserStatus(res.data.nextStep);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getInversorQuestions = createAsyncThunk(
  "get-inversors-questions",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.getInversorQuestions();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCityAndStateByZipcode = createAsyncThunk(
  "get-city-state-zipcode",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.getCityAndStateByZipcode(
        data
      );
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const referralCheck = createAsyncThunk(
  "referralCheck",
  async (referralCode: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.onboarding.referralCheck(referralCode);
      // Amplitude Evento + Event properties
      const baseEvent = {
        event_type:  'Referal Code Completed',
        event_properties: { referral_code: referralCode},
    }
    amplitude.track(baseEvent)
    //
      return res;
    } catch (err: any) {
      if (!err.response) {
        amplitude.track('Referal Code Invalid');
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const onboardingIdentityErrors: IdentityErrorTypes = {
  age: {
    referenceImage: "",
    cause: "todavía no cumpliste los 18",
    fixState: "seas mayor de edad",
  },
  dni: {
    referenceImage: "",
    cause: "tu DNI está expirado",
    fixState: "tengas el DNI actualizado",
  },
  cuit: {
    referenceImage: "",
    cause: "tu CUIT es invalido",
    fixState: "puedas validar tu CUIT",
  },
};

// const testSteps: Array<StepContent> = [
//   {
//     question: "¿Cuánto conocimiento tenés sobre inversiones?",
//     answers: [
//       {
//         label: "No tengo ningún tipo de conocimiento",
//         // labelHover: "No tengo ningun tipo de conocimiento sobre inversiones",
//         value: 0,
//       },
//       {
//         label: "Tengo un poco de conocimiento",
//         labelHover: "Tengo un poco de conocimiento sobre inversiones",
//         value: 1
//       },
//       {
//         label: "Tengo bastante conocimiento",
//         labelHover: "Estoy informado/a sobre inversiones",
//         value: 2
//       },
//       {
//         label: "Soy experta/o en inversiones",
//         labelHover: "Me considero un/a experto/a en inversiones",
//         value: 3,
//       },
//     ],
//   },
//   {
//     question:
//       "¿Qué porcentaje de tus ahorros destinarías a inversiones?",
//     answers: [
//       { label: "Menos del 25%", value: 0 },
//       { label: "Entre 26% y el 40%", value: 1 },
//       { label: "Entre el 41% y el 65%", value: 2 },
//       { label: "Más del 65%", value: 3 },
//     ],
//   },
//   {
//     question: "¿Alguna vez invertiste?",
//     answers: [
//       { label: "Nunca invertí", value: 0 },
//       {
//         label: "Alguna vez compré dólares o puse dinero en un plazo fijo",
//         value: 1,
//       },
//       { label: "Suelo invertir, pero desde mi banco", value: 2 },
//       { label: "Invierto y tengo conocimiento de inversiones", value: 3 },
//     ],
//   },
//   {
//     question: "Si tu inversión cae 15% en un mes ¿Qué harías?",
//     answers: [
//       {
//         label:
//           "Vendo si el valor baja sifnificativamente y no me arriesgo a seguir perdiendo",
//         value: 0,
//       },
//       { label: "Espero a que vuelva a estabilizarse y retiro todo", value: 1 },
//       {
//         label: "Espero a que la inversión se recupere y genere ganancias",
//         value: 2,
//       },
//       {
//         label:
//           "Aprovecho la oportunidad de aumentar mi inversión, sabiendo que a futuro podría mejorar e incluso obtener una mayor ganancia",
//         value: 3,
//       },
//     ],
//   },

//   {
//     question:
//       "Una vez invertido el dinero, ¿En cuánto tiempo lo necesitarias disponible?",
//     answers: [
//       { label: "Menos de 6 meses", value: 0 },
//       { label: "Entre 6 meses y 1 año", value: 1 },
//       { label: "Entre 1 y 2 años", value: 2 },
//       { label: "Más de 2 años", value: 3 },
//     ],
//   }
// ];
export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    // ------------- //
    setUserInfo: (state, action) => {
      state.userInfo = { ...state.userInfo, ...action.payload.info };
      if (action.payload.nextStep)
        state.onboardingStep = (state.onboardingStep ?? 1) + 1;
    },
    cancelNeedPersistProtectedState: (state) => {
      state.needPersistProtectedState=false;
    },
    forceNeedPersistProtectedState: (state) => {
      state.needPersistProtectedState=true;
    },
    resetTest: (state) => {
      state.testQuestion = 1;
      state.testStepContent = state.testSteps[1];
      state.answerArray = Array(5).fill("");
      state.resultTest = null;
    },
    resetValidation: (state) => {
      state.identityValidationError = null;
    },
    changeOnboardingStep: (state, action: PayloadAction<any>) => {
      state.onboardingStep = action.payload;
    },
    getValidation: (state, action: PayloadAction<any>) => {
      state.identityValidationError =
        onboardingIdentityErrors[action.payload] ?? undefined;
    },
    nextOnboardingStep: (state) => {
      state.onboardingStep =
        state.onboardingStep ?? 1 + 1 > 13
          ? 13
          : (state.onboardingStep ?? 1) + 1;
    },

    nextQuestion: (state) => {
      state.testQuestion =
        state.testQuestion + 1 > 5 ? 5 : state.testQuestion + 1;
    },
    previousQuestion: (state) => {
      state.testQuestion =
        state.testQuestion - 1 < 1 ? 1 : state.testQuestion - 1;
    },
    getStepContent: (state, action: PayloadAction<any>) => {
      state.testStepContent = state.testSteps[action.payload];
    },
    loadAnswersByPosition: (state, action: PayloadAction<any>) => {
      state.answerArray[action.payload.pos] = action.payload.content;
    },
    getTestResult: (state) => {
      if (state.answerArray[0] == state.testSteps[0].answers[0].label)
        state.resultTest = "Conservador";
      else if (
        state.answerArray[0] ==
        "Tengo un poco de conocimiento sobre inversiones"
      )
        state.resultTest = "Moderado";
      else if (state.answerArray[0] == "Estoy informado/a sobre inversiones")
        state.resultTest = "Arriesgado";
      else state.resultTest = "Moderado";
    },
    cleanErrorState: (state) => {
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    clearOnboardingStore: (state) => {
      Object.assign(state, initialState);
    },
    setWaitValidationStatus: (state, action: PayloadAction<any>) => {
      state.waitingValidation = action.payload;
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    // getBookmarks
    [begin.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
      state.prevUserInfo = { ...state.prevUserInfo, nextStep: "SET-NICKNAME" };
      state.photoIDCardFrontValid = null;
      state.photoIDCardBackValid = null;
      state.photoSelfieValid = null;
    },
    [begin.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [begin.rejected.type]: (state, action) => {
      state.isLoading = false;
      if (action.payload.errorCode === "")
        state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [nickname.fulfilled.type]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.errorMessage = null;
      state.onboardingStep = 2;
      state.errorCode = null;
    },
    [nickname.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [nickname.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [referralCheck.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [referralCheck.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [referralCheck.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },
    [uploadDni.fulfilled.type]: (state, action) => {
      state.photoIDCardFrontValid = state.photoIDCardFrontValid
        ? state.photoIDCardFrontValid
        : null;
      state.photoIDCardBackValid = state.photoIDCardBackValid
        ? state.photoIDCardBackValid
        : null;
      state.isLoading = false;
      state.errorMessage = null;
      state.onboardingStep = state.photoSelfieValid ? 4 : 3;
      state.errorCode = null;
      state.prevUserInfo = state.photoSelfieValid
        ? { ...state.prevUserInfo, nextStep: "WAIT" }
        : { ...state.prevUserInfo, nextStep: "GET-GESTURE" };
    },
    [uploadDni.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [uploadDni.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.onboardingStep = state.photoSelfieValid ? 4 : 3;
      state.errorCode = action.payload.errorCode;
    },
    [selfieGesture.fulfilled.type]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.selfieGesture = action.payload.data.gesture;
      state.errorMessage = null;
    },
    [selfieGesture.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [selfieGesture.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [uploadSelfie.fulfilled.type]: (state, action) => {
      // console.log(action);
      state.photoSelfieValid = null;
      state.isLoading = false;
      state.errorMessage = null;
      state.onboardingStep = 4;
      state.prevUserInfo = { ...state.prevUserInfo, nextStep: "WAIT" };
      state.errorCode = null;
    },
    [uploadSelfie.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [uploadSelfie.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [uploadUserInfo.fulfilled.type]: (state, action) => {
      state.prevUserInfo = action?.payload?.data;
      // console.log(action);
      // console.log(action, "action");
      state.isLoading = false;
      state.errorMessage = null;
      state.onboardingStep = 11;
      state.errorCode = null;
    },
    [uploadUserInfo.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [uploadUserInfo.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [uploadSignature.fulfilled.type]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.errorMessage = null;
      state.onboardingStep = 12;
      state.errorCode = null;
    },
    [uploadSignature.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [uploadSignature.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [inversorQuestions.fulfilled.type]: (state, action) => {
      state.resultTest = action?.payload?.data?.profile;
      state.isLoading = false;
      state.errorMessage = null;
      state.onboardingStep = 13;
      state.errorCode = null;
    },
    [inversorQuestions.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [inversorQuestions.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [getUserInfo.fulfilled.type]: (state, action) => {
      state.prevUserInfo = action?.payload?.data;
      state.photoIDCardFrontValidError =
        action?.payload?.data.dniFrontValidationError;
      state.photoIDCardBackValidError =
        action?.payload?.data.dniBackValidationError;
      state.photoSelfieValidError = action?.payload?.data.selfieValidationError;
      if (!action?.payload?.isInitialRequest) {
        (state.photoIDCardFrontValid =
          (action?.payload?.data.dniFrontPendingValidation ||
            action?.payload?.data.dniFrontValidationInProgress) &&
          !(
            action?.payload?.data.dniFrontPendingValidation &&
            !action?.payload?.data.dniFrontValidationInProgress
          )
            ? null
            : action?.payload?.data.dniFrontValidated),
          (state.photoIDCardBackValid =
            (action?.payload?.data.dniBackPendingValidation ||
              action?.payload?.data.dniBackValidationInProgress) &&
            !(
              action?.payload?.data.dniBackPendingValidation &&
              !action?.payload?.data.dniBackValidationInProgress
            )
              ? null
              : action?.payload?.data.dniBackValidated),
          (state.photoSelfieValid =
            !action?.payload?.data.dniFrontPendingValidation &&
            !action?.payload?.data.dniFrontValidationInProgress &&
            !action?.payload?.data.dniFrontValidated &&
            !action?.payload?.data.selfieValidationInProgress &&
            action?.payload?.data.selfiePendingValidation
              ? true
              : action?.payload?.data.selfiePendingValidation ||
                action?.payload?.data.selfieValidationInProgress
              ? null
              : action?.payload?.data.selfieValidated);

        // : action?.payload?.data.dniFrontValidated? action?.payload?.data.selfieValidated:!action?.payload?.data.dniFrontValidated,
      } else {
        state.photoIDCardFrontValid = action?.payload?.data.dniFrontValidated;
        state.photoIDCardBackValid = action?.payload?.data.dniBackValidated;
        state.photoSelfieValid = action?.payload?.data.selfieValidated;
      }
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getUserInfo.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getUserInfo.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
      state.errorCodeGetUserInfo = action.payload?.errorCode;
    },
    [getInversorQuestions.fulfilled.type]: (state, action) => {
      state.testSteps = action?.payload?.data.questions;
      state.testStepContent = action?.payload?.data.questions[0];
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getInversorQuestions.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getInversorQuestions.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [getCityAndStateByZipcode.fulfilled.type]: (state, action) => {
      state.zipInfo = action?.payload?.data;
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getCityAndStateByZipcode.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getCityAndStateByZipcode.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
    },
    [finalize.fulfilled.type]: (state, action) => {
      state.needPersistProtectedState=true;
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [finalize.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [finalize.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [getUserInfoForThePolling.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getUserInfoForThePolling.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getUserInfoForThePolling.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
  },
});

export const {
  loadAnswersByPosition,
  changeOnboardingStep,
  getStepContent,
  getTestResult,
  resetTest,
  nextQuestion,
  previousQuestion,
  nextOnboardingStep,
  getValidation,
  resetValidation,
  setUserInfo,
  setWaitValidationStatus,
  cleanErrorState,
  clearOnboardingStore,
  cancelNeedPersistProtectedState,
  forceNeedPersistProtectedState
} = onboardingSlice.actions;

export const onboardingSelector = (state: RootState) => state.onboarding;
export default onboardingSlice.reducer;
