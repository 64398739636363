import { getRefreshToken } from "utility/storage/auth";
import {
  axiosDataInstance,
  axiosPaymentInstance,
  axiosOnboardingInstance,
  axiosAuthInstance,
} from "./axiosInstances";

export default {
  getAccount: () => axiosDataInstance.get(`/market/account/`),
  getActivityNotification: () =>
    axiosPaymentInstance.post(`/activity_notification`),
  getProfile: () => axiosAuthInstance.get(`/profile`),
  patchProfile: (data) => axiosAuthInstance.patch(`/profile/address`, data),
  patchNickname: (data) => axiosAuthInstance.patch(`/profile/nickname`, data),
  patchPicture: (data) => axiosAuthInstance.patch(`/profile/picture`, data),
  patchSettings: (data) => axiosAuthInstance.patch(`/profile/settings`, data),
  getFaqs: () => axiosOnboardingInstance.get(`/faqs`),
};
