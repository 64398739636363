import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getNavbarState, setNavbarState } from "utility/storage/market";
import type { RootState } from "../store";
import { dollarMepDataBuy, dollarMepDataSell, MarketState } from "./marketTypes";
import dolarMEP from "../../assets/img/DolarMEPPic.png";
import { getUserStatus } from "utility/storage/auth";
import { logEvent } from "firebase/analytics";
import { analytics } from "utility/helpers/firebaseHelper";
import * as amplitude from '@amplitude/analytics-browser';

export const initialState: MarketState = {
  navbarState: getNavbarState(),
  orders: undefined,
  expensesDisclaimer: null,
  operationDisclaimer: null,
  tern: "7D",
  marketInfo: undefined,
  instrumentDetail: undefined,
  instrumentHistory: [],
  investmentDetail: [],
  instrumentRates: undefined,
  userAccounts: [],
  investments: [],
  tendencies: [],
  userHoldings: [],
  dolarMEP: null,
  dolarMepNew: null,
  dolarMEPExpenses: null,
  isLoadingMepExpenses: false,
  dolarMEPExpensesData: null,
  instruments: [],
  instrumentsMP: [],
  instrumentsAction: [],
  instrumentsBond: [],
  instrumentsCertificate: [],
  instrumentsFund: [],
  marketErrorMessage: "",
  marketErrorCode: undefined,
  cartErrorMessage: "",
  cartErrorCode: undefined,
  orderDeleteErrorMessage: "",
  orderDeleteErrorCode: undefined,
  isLoadingOrders: false,
  favorites: [],
  isLoadingFavorite: false,
  favoritesErrorCode: undefined,
  favoritesErrorMessage: "",
  isLoading: false,
  equitiesIndustries: [],
  hideAmounts: false,
  allowInvest: false,
  allProfitLoss: [],
  isLoadingProfitLoss: undefined,
  getPdfUrlOrderMessage: "",
  getPdfUrlOrderErrorCode: undefined,
  isLoadingPdf: false,
  isLoadingExpenses: true,
  instrumentFundLiquidationDate: "",
  instrumentFundEntryDate: "",
  instrumentOperationIsSelling: false,
  dollarMepData: null,
  tickerMmFciInfo: null,
  dateDolarMep: null,
};

export const getMarketInfo = createAsyncThunk(
  "market-info",
  async (argument: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.market.getMarketInfo();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getUserHoldings = createAsyncThunk(
  "get-user-holdings",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getUserHoldings();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getUserAccount = createAsyncThunk(
  "get-user-account",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getUserAccount();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getInstrumentDetail = createAsyncThunk(
  "get-instrument",
  async (ticker: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getInstrumentDetail(ticker);
      if (res.data.instrumentType === "InstrumentPack") {
        res.data.instruments = res.data.instruments.filter(
          (item) => item.marketData
        );
      }
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getInstrumentHistory = createAsyncThunk(
  "get-instrument-history",
  async (arg: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getInstrumentHistory(arg);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const changeNavbarState = createAsyncThunk(
  "change-navbar-state",
  async (argument: any, thunkAPI: any) => {
    try {
      // const res = await thunkAPI.extra.market.changeNavbarState(argument);
      return argument;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getInstruments = createAsyncThunk(
  "get-instruments",
  async (tag: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getInstruments(tag);
      res.data.instruments.unshift({
        ticker: "MEP",
        instrumentType: "InstrumentMEP",
        name: "Dolar MEP",
        currency: "ARS",
        iconUrl: dolarMEP,
        profile: "Moderado",
        tags: ["Lite", "Tendencias"],
      });
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getDolarMEP = createAsyncThunk(
  "get-dolarMEP",
  async (arg: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getDolarMEP();

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getDolarMepNew = createAsyncThunk(
  "get-dolarMep-new",
  async (arg: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getDolarMepNew();

      return res;
    } catch (err: any) {
      if (err.response) {
        const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Subscribe Order Rejected', { 
          order_id: err.data.codOrden,
          instrumentType: "MEP",
          currency: state.instrumentDetail.currency,
          amount: err.data.importe,
          ticker: err.data.ticker,
          quantity: err.data.cantidad,
          reason: err.data.message
        });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const buyDolarMEP = createAsyncThunk(
  "buy-dolarMEP",
  async (data: dollarMepDataBuy, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.buyDolarMEP(data);
      logEvent(analytics, "purchase")
      const state = thunkAPI.getState().market as MarketState;
      amplitude.track('Subscribe Order Placed', { 
        order_id: res.data.codOrden,
        instrumentType: "MEP",
        currency: state.instrumentDetail.currency,
        amount: parseFloat(res.data.importe),
        ticker: res.data.ticker,
        quantity: res.data.cantidad,
       });
      return res;
    } catch (err: any) {
      if (err.response) {
        const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Subscribe Order Rejected', { 
          instrumentType: "MEP",
          currency: state.instrumentDetail.currency,
          // amount: err.data.importe,
          ticker: state.instrumentDetail.ticker,
          // quantity: err.data.cantidad,
          reason: err.data.message
        });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sellDolarMEP = createAsyncThunk(
  "sell-dolarMEP",
  async (data: dollarMepDataSell, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.sellDolarMEP({data});
      const state = thunkAPI.getState().market as MarketState;
      amplitude.track('Unsubscribe Order Placed', { 
        order_id: res.data.codOrden,
        instrumentType: "MEP",
        currency: state.instrumentDetail.currency,
        amount: parseFloat(res.data.importe),
        ticker: res.data.ticker,
        quantity: res.data.cantidad,
       });
      return res;
    } catch (err: any) {
      if (!err.response) {
        const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Unsubscribe Order Rejected', { 
          instrumentType: "MEP",
          currency: state.instrumentDetail.currency,
          // amount: err.data.importe,
          ticker: state.instrumentDetail.ticker,
          // quantity: err.data.cantidad,
          reason: err.data.message
        });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getExpensesDolarMEP = createAsyncThunk(
  "get-expenses-dolarMEP",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getDolarMEPExpenses(
        argument.purchaseData
      );
      // if(res.status===200){
      //   setTimestamp()
      // }
      // res.data = { ...res.data, toGetCoefficients: argument.toGetCoefficients };
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getDateDolarMep = createAsyncThunk(
  "get-date-dolarMEP",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getDateDolarMep(
        argument.purchaseData
      );
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const buyPack = createAsyncThunk(
  "buy-pack",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.buyPack(data);
      logEvent(analytics, "purchase")
      const state = thunkAPI.getState().market as MarketState;
      if (Array.isArray(res?.data)) {
        const codOrdenArray = res?.data?.map((item: any) => item.codOrden);
        const amountArray = res?.data?.reduce((total, item) => {
          // Convierte el valor de 'importe' a número y lo va sumando
          return total + parseFloat(item.importe);
        }, 0);
        const tickerArray = res?.data?.map((item: any) => item.ticker);
        amplitude.track('Buy Order Placed', {
          order_id: codOrdenArray,
          instrumentType: "PACK",
          currency: state?.instrumentDetail?.currency,
          amount: amountArray,
          ticker: tickerArray,
          quantity: res?.data[0]?.cantidad, // Utilizamos la cantidad del primer elemento
        });
      }
      return res;
    } catch (err: any) {
      if (!err.response) {
        const state = thunkAPI.getState().market as MarketState;
          amplitude.track('Buy Order Rejected', {
            instrumentType: "PACK",
          currency: state.instrumentDetail.currency,
          // amount: err.data.importe,
          ticker: state.instrumentDetail.ticker,
          // quantity: err.data.cantidad,
          reason: err.data.message
          });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const buyFCI = createAsyncThunk(
  "buy-fci",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.buyFCI(data);
      logEvent(analytics, "purchase")
      const state = thunkAPI.getState().market as MarketState;
      if (Array.isArray(res?.data)) {
        const codOrdenArray = res?.data?.map((item: any) => item.codSolicitudFdo);
        const amountArray = res?.data?.reduce((total, item) => {
          // Convierte el valor de 'importe' a número y lo va sumando
          return total + parseFloat(item.importe);
        }, 0);
        const quantityParts = res?.data?.reduce((total, item) => {
          return total + parseFloat(item.cantCuotapartes)
        }, 0)
        const tickerArray = res?.data?.map((item: any) => item.ticker);
        amplitude.track('Buy Order Placed', {
          order_id: codOrdenArray,
          instrumentType: "PACKFUND",
          currency: state?.instrumentDetail?.currency,
          amount: amountArray,
          ticker: tickerArray,
          quantity: quantityParts, //sumamos las cantidades de los instrumentos ya que normalmente se compra por importe 
        });
      }
      return res;
    } catch (err: any) {
      if (!err.response) {
        const state = thunkAPI.getState().market as MarketState;
          amplitude.track('Subscribe Order Rejected', {
            instrumentType: "PACKFUND",
            currency: state.instrumentDetail.currency,
            // amount: err.data.importe,
            ticker: state.instrumentDetail.ticker,
            // quantity: err.data.cantidad,
            reason: err.data.message
          });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const buyFund = createAsyncThunk(
  "fund-pack",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.buyFund(data);
      logEvent(analytics, "purchase")
      const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Subscribe Order Placed', { 
          order_id: res.data.codSolicitudFdo,
          instrumentType: "FUND",
          currency: state.instrumentDetail.currency,
          amount:  parseFloat(res.data.importe),
          ticker: res.data.ticker,
          quantity: res.data.cantCuotapartes,
         });
      return res;
    } catch (err: any) {
      if (!err.response) {
        const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Subscribe Order Rejected', { 
          instrumentType: "FUND",
          currency: state.instrumentDetail.currency,
          // amount: err.data.importe,
          ticker: state.instrumentDetail.ticker,
          // quantity: err.data.cantidad,
          reason: err.data.message
        });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const sellFund = createAsyncThunk(
  "fund-pack",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.sellFund(data);
      const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Unsubscribe Order Placed', { 
          order_id: res.data.codSolicitudFdo,
          instrumentType: "FUND",
          currency: state.instrumentDetail.currency,
          amount: parseFloat(res.data.importe),
          ticker: res.data.ticker,
          quantity: res.data.cantidad,
        });
      return res;
    } catch (err: any) {
      if (!err.response) {
        const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Unsubscribe Order Rejected', { 
          instrumentType: "FUND",
          currency: state.instrumentDetail.currency,
          // amount: err.data.importe,
          ticker: state.instrumentDetail.ticker,
          // quantity: err.data.cantidad,
          reason: err.data.message
         });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getInstrumentExpenses = createAsyncThunk(
  "get-expenses-instrument",
  async (argument: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getInstrumentExpenses(argument);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const buyStock = createAsyncThunk(
  "fund-pack",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.orderSell(data);
      logEvent(analytics, "purchase")
      const state = thunkAPI.getState().market as MarketState;
      amplitude.track('Buy Order Placed', { 
        order_id: res.data.codOrden,
        instrumentType: state.instrumentDetail.instrumentType === "InstrumentBond" ? "BOND" : state.instrumentDetail.instrumentType === "InstrumentEquity" ? "EQUITY" : "CERTIFICATE",
        currency: state.instrumentDetail.currency,
        amount: parseFloat(res.data.importe),
        ticker: res.data.ticker,
        quantity: res.data.cantidad,
      });

      return res;
    } catch (err: any) {
      if (!err.response) {
        const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Buy Order Rejected', { 
        instrumentType: state.instrumentDetail.instrumentType === "InstrumentBond" ? "BOND" : state.instrumentDetail.instrumentType === "InstrumentEquity" ? "EQUITY" : "CERTIFICATE",
        currency: state.instrumentDetail.currency,
        // amount: err.data.importe,
        ticker: state.instrumentDetail.ticker,
          // quantity: err.data.cantidad,
        reason: err.data.message
      });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const sellStock = createAsyncThunk(
  "fund-pack",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.orderSell(data);
      // AMPLITUDE
      const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Sell Order Placed', { 
            order_id: res.data.codOrden,
            instrumentType: state.instrumentDetail.instrumentType === "InstrumentBond" ? "BOND" : state.instrumentDetail.instrumentType === "InstrumentEquity" ? "EQUITY" : "CERTIFICATE",
            currency: state.instrumentDetail.currency,
            amount: parseFloat(res.data.importe),
            ticker: res.data.ticker,
            quantity: res.data.cantidad,
         });
      return res;
    } catch (err: any) {
      if (!err.response) {
        const state = thunkAPI.getState().market as MarketState;
        amplitude.track('Sell Order Rejected', { 
          instrumentType: state.instrumentDetail.instrumentType === "InstrumentBond" ? "BOND" : state.instrumentDetail.instrumentType === "InstrumentEquity" ? "EQUITY" : "CERTIFICATE",
          currency: state.instrumentDetail.currency,
          // amount: err.data.importe,
          ticker: state.instrumentDetail.ticker,
          // quantity: err.data.cantidad,
          reason: err.data.message
        });
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getTendencies = createAsyncThunk(
  "get-tendencies",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getTendencies();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// MODO PRO

export const getAllActions = createAsyncThunk(
  "get-allActions",
  async (_: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.market.getAllActions();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAllCertificates = createAsyncThunk(
  "get-allCertificates",
  async (_: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.market.getAllCertificates();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAllPacks = createAsyncThunk(
  "get-allPacks",
  async (_: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.market.getAllPacks();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAllBonds = createAsyncThunk(
  "get-allBonds",
  async (_: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.market.getAllBonds();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAllFunds = createAsyncThunk(
  "get-allFunds",
  async (_: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.market.getAllFunds();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getFavorites = createAsyncThunk(
  "get-favorites",
  async (_: void, thunkAPI: any) => {
    try {
      let res;
      if (getUserStatus() === "MANUAL-VERIFICATION") {
        res = [];
      } else {
        res = await thunkAPI.extra.market.getFavorites();
      }
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postFavorite = createAsyncThunk(
  "post-favorites",
  async (data: any, thunkAPI: any) => {
    try {
      await thunkAPI.extra.market.postFavorite(data);
      return await thunkAPI.extra.market.getFavorites();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteFavorite = createAsyncThunk(
  "delete-favorites",
  async (ticker: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.deleteFavorite(ticker);
      res.data = {
        isSuccess: res.data,
        ticker: ticker,
      };
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const searchInstruments = createAsyncThunk(
  "search-instruments",
  async (ticker: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.searchInstruments(ticker);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getProfitLoss = createAsyncThunk(
  "get-profitloss",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getProfitLoss(data);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postProfitLoss = createAsyncThunk(
  "post-profitloss",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.postProfitLoss(data);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const putProfitLoss = createAsyncThunk(
  "put-profitloss",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.putProfitLoss(data);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteProfitLoss = createAsyncThunk(
  "delete-profitloss",
  async (id: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.deleteProfitLoss(id);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getIndustriesEquities = createAsyncThunk(
  "get-equitiesIndustry",
  async (_: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getIndustriesEquities();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getFundDate = createAsyncThunk(
  "get-fund-dates",
  async (ticker: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getFundDate(ticker);
      const data = res.data;
      return {
        closeTime: data?.closeTime,
        entryDate: data?.entryDate,
        liquidationDate: data?.liquidationDate,
    };
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getInstrumentProfit = createAsyncThunk(
  "get-instrument-profit",
  async (ticker: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getInstrumentProfit(ticker);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postAllowInvest = createAsyncThunk(
  "post-allow-invest",
  async (_: void, thunkAPI: any) => {
    try {
      return true;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAllowInvest = createAsyncThunk(
  "get-allow-invest",
  async (data: any, thunkAPI: any) => {
    try {
      return false;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getOrders = createAsyncThunk(
  "get-orders",
  async (arg: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getOrders();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getOrdersfdo = createAsyncThunk(
  "get-ordersfdo",
  async (arg: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getOrdersfdo();
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteOrder = createAsyncThunk(
  "delete-order",
  async (orderId: number, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.deleteOrder(orderId);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteOrderfdo = createAsyncThunk(
  "delete-orderfdo",
  async (orderId: number, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.deleteOrderfdo(orderId);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getPdfUrlOrder = createAsyncThunk(
  "get-pfd-order",
  async (orderId: number, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getPdfUrlOrder(orderId);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getMmFciInfo = createAsyncThunk(
  "get-mm-fci-info",
  async (argument: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.market.getMmFciInfo();
      return res.data.ticker;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
    return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    // Sync Reducers //
    cleanErrorState: (state) => {
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
      state.orderDeleteErrorCode = undefined;
      state.orderDeleteErrorMessage = "";
    },
    cleanOrders: (state) => {
      state.orders = undefined;
    },
    cleanInstrumentDetail: (state) => {
      state.instrumentDetail = undefined;
    },
    setGraphTern: (state, action: any) => {
      state.tern = action.payload;
    },
    setHideAmounts: (state) => {
      state.hideAmounts = !state.hideAmounts;
    },
    cleanPdfOrders: (state) => {
      state.isLoadingPdf = false;
      state.getPdfUrlOrderErrorCode = undefined;
      state.getPdfUrlOrderMessage = "";
    },
    clearMarketStore: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    [getMarketInfo.fulfilled.type]: (state, action) => {
      state.marketInfo = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getMarketInfo.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getMarketInfo.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getInstrumentDetail.fulfilled.type]: (state, action) => {
      state.instrumentDetail = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getInstrumentDetail.pending.type]: (state, action) => {
      state.instrumentDetail = undefined;
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getInstrumentDetail.rejected.type]: (state, action) => {
      state.instrumentDetail = undefined;
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [changeNavbarState.fulfilled.type]: (state, action) => {
      state.navbarState = action.payload;
      setNavbarState(action.payload);
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [changeNavbarState.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [changeNavbarState.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getUserHoldings.fulfilled.type]: (state, action) => {
      state.userHoldings = action.payload?.holdings;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getUserHoldings.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getUserHoldings.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getUserAccount.fulfilled.type]: (state, action) => {
      state.userAccounts = action.payload?.posicionesMon;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getUserAccount.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getUserAccount.rejected.type]: (state, action) => {
      state.userAccounts = [];
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getDolarMEP.fulfilled.type]: (state, action) => {
      state.dolarMEP = action?.payload?.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getDolarMEP.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getDolarMEP.rejected.type]: (state, action) => {
      state.dolarMEP = null;
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getDolarMepNew.fulfilled.type]: (state, action) => {
      state.dolarMepNew = action?.payload?.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getDolarMepNew.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getDolarMepNew.rejected.type]: (state, action) => {
      state.dolarMEP = null;
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [buyDolarMEP.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cartErrorCode = null;
      state.cartErrorMessage = "";
    },
    [buyDolarMEP.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [buyDolarMEP.rejected.type]: (state, action) => {
      state.isLoading = false;
      // state.errorCode = action.payload?.errorCode ?? "ErrorCode.Not_allowed";
      state.cartErrorCode = action?.payload?.errorCode;
      state.cartErrorMessage = action?.payload?.errorMessage;
    },
    [sellDolarMEP.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cartErrorCode = null;
      state.cartErrorMessage = "";
    },
    [sellDolarMEP.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [sellDolarMEP.rejected.type]: (state, action) => {
      state.isLoading = false;
      // state.errorCode = action.payload?.errorCode ?? "ErrorCode.Not_allowed";
      state.cartErrorCode = action.payload.errorCode;
      state.cartErrorMessage = action.payload.errorMessage;
    },
    [getExpensesDolarMEP.fulfilled.type]: (state, action) => {
      state.dolarMEPExpenses = action?.payload?.data?.expenses;
      state.dolarMEPExpensesData = {
        expensesDisclaimer: action?.payload?.data.expensesDisclaimer,
        operationDisclaimer: action?.payload?.data.operationDisclaimer,
        available: action?.payload?.data.available,
      };
      state.expensesDisclaimer = action?.payload?.data.expensesDisclaimer;
      state.operationDisclaimer = action?.payload?.data.operationDisclaimer;
      state.isLoading = false;
      state.isLoadingMepExpenses = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getExpensesDolarMEP.pending.type]: (state, action) => {
      state.dolarMEPExpenses = null;
      state.isLoading = true;
      state.isLoadingMepExpenses = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getExpensesDolarMEP.rejected.type]: (state, action) => {
      state.dolarMEPExpenses = null;
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.isLoadingMepExpenses = false;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getDateDolarMep.fulfilled.type]: (state, action) => {
      state.dateDolarMep = action?.payload?.data?.available;
      state.expensesDisclaimer = action?.payload?.data?.expensesDisclaimer;
      state.operationDisclaimer = action?.payload?.data?.operationDisclaimer;
      state.isLoading = false;
      state.isLoadingMepExpenses = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getDateDolarMep.pending.type]: (state, action) => {
      state.dateDolarMep = null;
      state.isLoading = true;
      state.isLoadingMepExpenses = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getDateDolarMep.rejected.type]: (state, action) => {
      state.dateDolarMep = null;
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.isLoadingMepExpenses = false;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [buyPack.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cartErrorCode = null;
      state.cartErrorMessage = "";
    },
    [buyPack.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [buyPack.rejected.type]: (state, action) => {
      state.isLoading = false;
      // state.errorCode = action.payload?.errorCode ?? "ErrorCode.Not_allowed";
      state.cartErrorCode = action?.payload?.errorCode;
      state.cartErrorMessage = action?.payload?.errorMessage;
    },
    [buyFCI.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cartErrorCode = null;
      state.cartErrorMessage = "";
    },
    [buyFCI.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [buyFCI.rejected.type]: (state, action) => {
      state.isLoading = false;
      // state.errorCode = action.payload?.errorCode ?? "ErrorCode.Not_allowed";
      state.cartErrorCode = action.payload.errorCode;
      state.cartErrorMessage = action.payload.errorMessage;
    },
    [buyFund.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cartErrorCode = null;
      state.cartErrorMessage = "";
    },
    [buyFund.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [buyFund.rejected.type]: (state, action) => {
      state.isLoading = false;
      // state.errorCode = action.payload?.errorCode ?? "ErrorCode.Not_allowed";
      state.cartErrorCode = action.payload.errorCode;
      state.cartErrorMessage = action.payload.errorMessage;
    },
    [buyStock.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cartErrorCode = null;
      state.cartErrorMessage = "";
    },
    [buyStock.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [buyStock.rejected.type]: (state, action) => {
      state.isLoading = false;
      // state.errorCode = action.payload?.errorCode ?? "ErrorCode.Not_allowed";
      state.cartErrorCode = action.payload.errorCode;
      state.cartErrorMessage = action.payload.errorMessage;
    },
    [sellStock.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.cartErrorCode = null;
      state.cartErrorMessage = "";
    },
    [sellStock.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [sellStock.rejected.type]: (state, action) => {
      state.isLoading = false;
      // state.errorCode = action.payload?.errorCode ?? "ErrorCode.Not_allowed";
      state.cartErrorCode = action.payload.errorCode;
      state.cartErrorMessage = action.payload.errorMessage;
    },
    [getInstruments.fulfilled.type]: (state, action) => {
      state.instruments = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getInstruments.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getInstruments.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getInstrumentDetail.fulfilled.type]: (state, action) => {
      state.instrumentDetail = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getInstrumentDetail.pending.type]: (state, action) => {
      state.instrumentDetail = undefined;
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getInstrumentDetail.rejected.type]: (state, action) => {
      state.instrumentDetail = undefined;
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getInstrumentExpenses.fulfilled.type]: (state, action) => {
      state.instrumentRates = action.payload.data;
      state.isLoadingExpenses = false;
      state.cartErrorMessage = "";
    },
    [getInstrumentExpenses.pending.type]: (state, action) => {
      state.instrumentRates = undefined;
      state.isLoadingExpenses = true;
      state.cartErrorCode = undefined;
      state.cartErrorMessage = "";
    },
    [getInstrumentExpenses.rejected.type]: (state, action) => {
      state.instrumentRates = undefined;
      state.isLoadingExpenses = false;
      state.cartErrorCode = action.payload.errorCode;
      state.cartErrorMessage = action.payload.errorMessage;
    },
    [getInstrumentHistory.fulfilled.type]: (state, action) => {
      state.instrumentHistory = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getInstrumentHistory.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getInstrumentHistory.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorCode;
      state.marketErrorMessage = action.payload?.errorMessage + ".history";
    },
    [getTendencies.fulfilled.type]: (state, action) => {
      state.tendencies = action.payload.data.instruments;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getTendencies.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getTendencies.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorCode;
      state.marketErrorMessage = action.payload?.errorMessage;
    },

    // MODO PRO
    [getAllActions.fulfilled.type]: (state, action) => {
      state.instrumentsAction = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getAllActions.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getAllActions.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getAllCertificates.fulfilled.type]: (state, action) => {
      state.instrumentsCertificate = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getAllCertificates.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getAllCertificates.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getAllFunds.fulfilled.type]: (state, action) => {
      state.instrumentsFund = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getAllFunds.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getAllFunds.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getAllPacks.fulfilled.type]: (state, action) => {
      state.instrumentsMP = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getAllPacks.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getAllPacks.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getAllBonds.fulfilled.type]: (state, action) => {
      state.instrumentsBond = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getAllBonds.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getAllBonds.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    //FAVORITES
    [getFavorites.fulfilled.type]: (state, action) => {
      state.favorites = action.payload.data || [];
      state.isLoadingFavorite = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getFavorites.pending.type]: (state, action) => {
      state.isLoadingFavorite = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getFavorites.rejected.type]: (state, action) => {
      state.isLoadingFavorite = false;
      state.favoritesErrorCode = action.payload.errorCode;
      state.favoritesErrorMessage = action.payload.errorMessage;
    },
    [postFavorite.fulfilled.type]: (state, action) => {
      state.favorites = action.payload.data;
      state.isLoadingFavorite = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [postFavorite.pending.type]: (state, action) => {
      state.isLoadingFavorite = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [postFavorite.rejected.type]: (state, action) => {
      state.isLoadingFavorite = false;
      state.favoritesErrorCode = action.payload.errorCode;
      state.favoritesErrorMessage = action.payload.errorMessage;
    },
    [deleteFavorite.fulfilled.type]: (state, action) => {
      if (action.payload.data.isSuccess) {
        const newFavorites = state?.favorites?.filter(
          (data) => data.ticker != action.payload.data.ticker
        );
        state.favorites = [...newFavorites];
      }
      state.isLoadingFavorite = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [deleteFavorite.pending.type]: (state, action) => {
      state.isLoadingFavorite = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [deleteFavorite.rejected.type]: (state, action) => {
      state.isLoadingFavorite = false;
      state.favoritesErrorCode = action.payload.errorCode;
      state.favoritesErrorMessage = action.payload.errorMessage;
    },
    [searchInstruments.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [searchInstruments.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [searchInstruments.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getIndustriesEquities.fulfilled.type]: (state, action) => {
      state.equitiesIndustries = action.payload.data;
      state.isLoading = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getIndustriesEquities.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getIndustriesEquities.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getOrders.fulfilled.type]: (state, action) => {
      state.orders = action.payload?.data?.map((order) => ({
        ...order,
        isFdo: false,
      }));
      state.isLoadingOrders = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getOrders.pending.type]: (state, action) => {
      // state.orders = undefined;
      state.isLoadingOrders = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getOrders.rejected.type]: (state, action) => {
      state.isLoadingOrders = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getOrdersfdo.fulfilled.type]: (state, action) => {
      // console.log("prev",state?.orders);
      // console.log("ssssss",action?.payload?.data);
      if (state?.orders !== undefined && action.payload.data !== undefined) {
        state.orders = [
          ...state.orders,
          ...action.payload.data.map((order) => ({ ...order, isFdo: false })),
        ];
      } else {
        state.orders = action.payload?.data?.map((order) => ({
          ...order,
          isFdo: false,
        }));
      }
      // state.orders = [
      //   ...(state?.orders ?? []),
      //   {
      //     cantCuotapartes: 254.947,
      //     ccTrxId: "1679",
      //     codMoneda: 1,
      //     codSolicitudFdo: 203846,
      //     codTpOperacion: "RE",
      //     createdAt: "2023-01-04 12:00:46",
      //     esTotal: false,
      //     estado: "En proceso",
      //     fechaConcertacion: "2023-01-04",
      //     hasTicket: false,
      //     iconUrl:
      //       "https://img.dev-banzaback.bnzlab.io/bonds/AdCap Ahorro Pesos Fondo de Dinero - Clase A.png",
      //     id: 108,
      //     importe: "500.0",
      //     instrumentType: "InstrumentFund",
      //     name: "AdCap Ahorro Pesos Fondo de Dinero - Clase A",
      //     precio: "1.96119",
      //     ticker: "AdCap Ahorro Pesos Fondo de Dinero - Clase A",
      //   },
      //   {
      //     aPrecioDeMercado: false,
      //     cantidad: 1,
      //     cantidadEjecutada: 0,
      //     cantidadPendiente: 1,
      //     codMoneda: 1,
      //     codOrden: 211517,
      //     codTpEstadoOrden: 1,
      //     compraVenta: "V",
      //     estado: "En envío",
      //     fechaConcertacion: "2023-01-04",
      //     fechaIngreso: "2023-01-04T16:18:50",
      //     fechaVencimiento: null,
      //     gastosEstim: "1.9400000000",
      //     hasTicket: false,
      //     iconUrl: "https://img.bnzlab.io/YPFD.jpg",
      //     id: 128,
      //     importe: "-890.0000000000",
      //     importeBrutoEjec: null,
      //     importeNeto: "-891.9400000000",
      //     incluyeGastosEnImporte: false,
      //     name: "YPF SA",
      //     plazo: 3,
      //     porImporte: false,
      //     precio: "890.0000000000",
      //     ticker: "YPFD",
      //   },
      // ];
      // console.log("post",state?.orders);
      state.isLoadingOrders = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getOrdersfdo.pending.type]: (state, action) => {
      state.isLoadingOrders = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getOrdersfdo.rejected.type]: (state, action) => {
      state.isLoadingOrders = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [deleteOrder.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.orderDeleteErrorCode = null;
      state.orderDeleteErrorMessage = "";
    },
    [deleteOrder.pending.type]: (state, action) => {
      // state.orders = undefined;
      state.isLoading = true;
      state.orderDeleteErrorCode = undefined;
      state.orderDeleteErrorMessage = "";
    },
    [deleteOrder.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.orderDeleteErrorCode = action.payload?.errorCode;
      state.orderDeleteErrorMessage = action.payload?.message;
    },
    [deleteOrderfdo.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.orderDeleteErrorCode = null;
      state.orderDeleteErrorMessage = "";
    },
    [deleteOrderfdo.pending.type]: (state, action) => {
      // state.orders = undefined;
      state.isLoading = true;
      state.orderDeleteErrorCode = undefined;
      state.orderDeleteErrorMessage = "";
    },
    [deleteOrderfdo.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.orderDeleteErrorCode = action.payload?.errorCode;
      state.orderDeleteErrorMessage = action.payload?.message;
    },
    [postAllowInvest.fulfilled.type]: (state, action) => {
      state.allowInvest = true;
      state.isLoading = false;
    },
    [postAllowInvest.pending.type]: (state, action) => {
      state.isLoading = true;
    },
    [getAllowInvest.fulfilled.type]: (state, action) => {
      state.allowInvest = false;
      state.isLoading = false;
    },
    [getAllowInvest.pending.type]: (state, action) => {
      state.isLoading = true;
    },
    [getProfitLoss.fulfilled.type]: (state, action) => {
      state.allProfitLoss = action.payload.data.processList;
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [getProfitLoss.pending.type]: (state, action) => {
      state.isLoadingProfitLoss = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [getProfitLoss.rejected.type]: (state, action) => {
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [postProfitLoss.fulfilled.type]: (state, action) => {
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [postProfitLoss.pending.type]: (state, action) => {
      state.isLoadingProfitLoss = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [postProfitLoss.rejected.type]: (state, action) => {
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [putProfitLoss.fulfilled.type]: (state, action) => {
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [putProfitLoss.pending.type]: (state, action) => {
      state.isLoadingProfitLoss = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [putProfitLoss.rejected.type]: (state, action) => {
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [putProfitLoss.fulfilled.type]: (state, action) => {
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = null;
      state.marketErrorMessage = "";
    },
    [putProfitLoss.pending.type]: (state, action) => {
      state.isLoadingProfitLoss = true;
      state.marketErrorCode = undefined;
      state.marketErrorMessage = "";
    },
    [putProfitLoss.rejected.type]: (state, action) => {
      state.isLoadingProfitLoss = false;
      state.marketErrorCode = action.payload?.errorMessage;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getPdfUrlOrder.fulfilled.type]: (state, action) => {
      state.isLoadingPdf = false;
      state.getPdfUrlOrderErrorCode = null;
      state.getPdfUrlOrderMessage = "";
    },
    [getPdfUrlOrder.pending.type]: (state, action) => {
      state.isLoadingPdf = true;
      state.getPdfUrlOrderErrorCode = undefined;
      state.getPdfUrlOrderMessage = "";
    },
    [getPdfUrlOrder.rejected.type]: (state, action) => {
      state.isLoadingPdf = false;
      state.getPdfUrlOrderErrorCode = action.payload?.errorCode;
      state.getPdfUrlOrderMessage = action.payload?.message;
    },
    [getFundDate.fulfilled.type]: (state, action) => {
      state.instrumentFundLiquidationDate = action?.payload?.liquidationDate;
      state.instrumentFundEntryDate = action?.payload?.entryDate;
      state.isLoading = false;
      state.marketErrorMessage = "";
    },
    [getFundDate.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorMessage = "";
    },
    [getFundDate.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorMessage = action.payload?.errorCode;
    },
    [getMmFciInfo.fulfilled.type]: (state, action) => {
      state.tickerMmFciInfo = action?.payload
      state.isLoading = false;
      state.marketErrorMessage = "";
    },
    [getMmFciInfo.pending.type]: (state, action) => {
      state.isLoading = true;
      state.marketErrorMessage = "";
    },
    [getMmFciInfo.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.marketErrorMessage = action.payload?.errorCode;
    },
  },
});

export const marketSelector = (state: RootState) => state.market;
export const {
  cleanOrders,
  cleanErrorState,
  cleanInstrumentDetail,
  setGraphTern,
  setHideAmounts,
  cleanPdfOrders,
  clearMarketStore,
} = marketSlice.actions;
export default marketSlice.reducer;
