import { getRefreshToken } from "utility/storage/auth";
import { axiosAnonInstance, axiosAuthInstance } from "./axiosInstances";

export default {
  // login: (data: FormData) => axiosAnonInstance.get("/login"),
  //CAMBIAR
  login: (data: any) =>
    axiosAuthInstance.post("/auth/login", {
      authUser: data.email,
      authSecret: data.password,
    }),
  signUp: (data: FormData) => axiosAuthInstance.post("/auth/register", data),
  refreshToken: (data: FormData) =>
    axiosAnonInstance.post("/auth/refreshToken", data), //{ refresh_token: getRefreshToken() }), // TODO: REVISAR BASE_URL
  emailVerify: (email: string) =>
    axiosAuthInstance.post("/register", {
      email,
    }),
  codeResend: (email: any) =>
    axiosAuthInstance.patch("/register/resend", {
      email,
    }),
  codeVerify: (data: any) =>
    axiosAuthInstance.patch("/register/verify", {
      code: data.code,
      email: data.email,
      client: data.client,
    }),
  recoverPasswordRequest: (email: string) =>
    axiosAuthInstance.post("/password_recovery", {
      email,
    }),
  recoverPasswordVerify: (data: any) =>
    axiosAuthInstance.patch("/password_recovery/verify", {
      code: data.code,
      email: data.email,
      client: data.client,
    }),
  recoverPasswordReset: (data: any) =>
    axiosAuthInstance.patch("/password_recovery/reset", {
      email: data.email,
      token: data.token,
      password: data.password,
    }),
    getAppMaintenance: () => 
      axiosAuthInstance.get("/app/info"),

};
