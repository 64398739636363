import auth from "./auth";
import navbar from "./navbar";
import market from "./market";
import onboarding from "./onboarding";
import userInfo from "./userInfo";
import transfer from "./transfer";
import wallet from "./wallet";
import cashflow from "./cashflow";
import notification from "./notification";
import content from "./content";

export default {
  auth,
  userInfo,
  navbar,
  market,
  onboarding,
  transfer,
  wallet,
  cashflow,
  notification,
  content,
};
