import { getRefreshToken } from "utility/storage/auth";
import {
  axiosJsonServerInstance,
  axiosDataInstance,
  axiosPaymentInstance,
} from "./axiosInstances";

export default {
  getBankAccount: (transferReceiverId) =>
    axiosPaymentInstance.get(`/bank_accounts/${transferReceiverId}`),
  getTransferAccount: () => axiosPaymentInstance.get(`/account/`),
  transfer: (data) => axiosPaymentInstance.post(`/transfer`, data),
  getContacts: () => axiosPaymentInstance.get(`/contacts`),
  updateContact: (data) => axiosPaymentInstance.put(`/contacts`, data),
  updateMyAlias: (newAlias) => axiosPaymentInstance.patch(`/account/edit/${newAlias}`),
  deleteContact: (id) =>
    axiosPaymentInstance.delete(`/contacts`, { data: { contactId: id } }),
  getConcepts: () => axiosPaymentInstance.get(`/concepts/`),
  postConcept: (name) => axiosPaymentInstance.post(`/concept/`, name),
  // getMovementsByPeriod: (data) =>axiosPaymentInstance.get(`/transfer/contact/${data.contactId}${data.fromDate?`?fromDate=${data.fromDate}`:""}${data.toDate?`&toDate=${data.toDate}`:""}`),
  getMovementsByPeriod: (data) =>
    axiosPaymentInstance.get(
      `/transfers/contact/${data?.contactId}?fromDate=${data?.fromDate}&toDate=${data?.toDate}`
    ),
  getMovementDetail: (transactionId) =>
    axiosPaymentInstance.get(`/transfer/details/${transactionId}`),
};
