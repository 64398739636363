import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export const initialState: any = {
  suggestions: [],
  bookmarks: [],
  investSectionSelected: "wallet",
  transferSectionSelected: "Contactos",
  currentTypeCurrencyAccount: "ars",
};

export const getBookmarks = createAsyncThunk(
  "get/getBookmarks",
  async (argument: any, thunkAPI: any) => {
    try {
      const result = await thunkAPI.extra.navbar.getBookmarks();
      return result;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateBookmarked = createAsyncThunk(
  "get/getBookmarks",
  async (argument: any, thunkAPI: any) => {
    try {
      const result = await thunkAPI.extra.navbar.updateBookmarked(argument);
      return result;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    // ------------- //
    setInvestSection: (state, action: PayloadAction<any>) => {
      state.investSectionSelected = action.payload;
    },
    setTransferSection: (state, action: PayloadAction<any>) => {
      state.transferSectionSelected = action.payload;
    },
    handleChangeCurrencyAccount: (state, action: PayloadAction<any>) => {
      state.currentTypeCurrencyAccount = action.payload;
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    // getBookmarks
    [getBookmarks.fulfilled.type]: (state, action) => {
      state.suggestions = action.payload.data;
      state.bookmarks = action.payload.bookmarks;
      state.isLoading = false;
      state.errorMessage = "";
    },
    [getBookmarks.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = "";
    },
    [getBookmarks.rejected.type]: (state, action) => {
      state.suggestions = [];
      state.bookmarks = [];
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
    },
    // updateBookmarked
    [updateBookmarked.fulfilled.type]: (state, action) => {
      let objectToUpdate;

      // ** find & update object
      state.suggestions.find((item) => {
        if (item.id === action.payload.id) {
          item.isBookmarked = !item.isBookmarked;
          objectToUpdate = item;
        }
      });

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(
        (x) => x.id === action.payload.id
      );

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate);
      } else {
        state.bookmarks.splice(bookmarkIndex, 1);
      }

      state.isLoading = false;
      state.errorMessage = "";
    },
    [updateBookmarked.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = "";
    },
    [updateBookmarked.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
    },
  },
});

export const { handleChangeCurrencyAccount, setInvestSection,setTransferSection } =
  navbarSlice.actions;

export const navbarSelector = (state: RootState) => state.navbar;
export default navbarSlice.reducer;
