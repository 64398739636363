import React, { useMemo } from "react";
import MaintenancePage from "./MaintenancePage";
import { useHistory, useLocation } from "react-router-dom";

const MaintenancePageContainer = (
  props: MaintenancePageProps
) => {
  

  const childProps = {
    ...props,
  };

  return <MaintenancePage {...childProps} />;
};

MaintenancePageContainer.defaultProps = {};

type MaintenancePageProps = {
  message: string;
  title: string;
};

export default MaintenancePageContainer;
