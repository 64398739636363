import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { CashflowState } from "./cashflowTypes";

export const initialState: CashflowState = {
  isLoading: false,
  cashflowErrorCode: undefined,
  cashflowErrorMessage: "",
  banks: [],
  isLoadingBanks: false,
  bank: undefined,
  isLoadingPatch: false,
  isLoadingDelete: false,
  cashoutId: null,
};

export const postCashout = createAsyncThunk(
  "post-cashout",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.cashflow.postCashout(data);
      
      return {
        cashoutId: res.data.id,
    };
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getBanks = createAsyncThunk(
  "get-banks",
  async (_: void, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.cashflow.getBanks();
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getBank = createAsyncThunk(
  "get-bank",
  async (data: any, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.cashflow.getBank(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const patchBank = createAsyncThunk(
  "patch-bank",
  async (data: any, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.cashflow.patchBank(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteBank = createAsyncThunk(
  "delete-bank",
  async (data: any, thunkAPI: any) => {
    try {
      return await thunkAPI.extra.cashflow.deleteBank(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const cashflowSlice = createSlice({
  name: "cashflow",
  initialState,
  reducers: {
    // Sync Reducers //
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    [postCashout.fulfilled.type]: (state, action) => {
      state.cashoutId = action.payload.cashoutId
      state.isLoading = false;
      state.cashflowErrorCode = null;
      state.cashflowErrorMessage = "";
    },
    [postCashout.pending.type]: (state, action) => {
      state.isLoading = true;
      state.cashflowErrorCode = undefined;
      state.cashflowErrorMessage = "";
    },
    [postCashout.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.cashflowErrorCode = action.payload?.errorCode;
      state.cashflowErrorMessage = action.payload?.message;
    },
    [getBanks.fulfilled.type]: (state, action) => {
      state.isLoadingBanks = false;
      state.banks = action.payload.data.accounts;
      state.cashflowErrorCode = null;
      state.cashflowErrorMessage = "";
    },
    [getBanks.pending.type]: (state, action) => {
      state.isLoadingBanks = true;
      state.cashflowErrorCode = undefined;
      state.cashflowErrorMessage = "";
    },
    [getBanks.rejected.type]: (state, action) => {
      state.isLoadingBanks = false;
      state.cashflowErrorCode = action.payload?.errorCode;
      state.cashflowErrorMessage = action.payload?.message;
    },
    [getBank.fulfilled.type]: (state, action) => {
      state.isLoadingBanks = false;
      state.bank = action.payload.data;
      state.cashflowErrorCode = null;
      state.cashflowErrorMessage = "";
    },
    [getBank.pending.type]: (state, action) => {
      state.isLoadingBanks = true;
      state.cashflowErrorCode = undefined;
      state.cashflowErrorMessage = "";
    },
    [getBank.rejected.type]: (state, action) => {
      state.isLoadingBanks = false;
      state.cashflowErrorCode = action.payload?.errorCode;
      state.cashflowErrorMessage = action.payload?.message;
    },
    [patchBank.fulfilled.type]: (state, action) => {
      state.isLoadingPatch = false;
      // state.cashflowErrorCode = null;
      // state.cashflowErrorMessage = "";
    },
    [patchBank.pending.type]: (state, action) => {
      state.isLoadingPatch = true;
      // state.cashflowErrorCode = undefined;
      // state.cashflowErrorMessage = "";
    },
    [patchBank.rejected.type]: (state, action) => {
      state.isLoadingPatch = false;
      // state.cashflowErrorCode = action.payload?.errorCode;
      // state.cashflowErrorMessage = action.payload?.message;
    },
    [deleteBank.fulfilled.type]: (state, action) => {
      state.isLoadingDelete = false;
      // state.cashflowErrorCode = null;
      // state.cashflowErrorMessage = "";
    },
    [deleteBank.pending.type]: (state, action) => {
      state.isLoadingDelete = true;
      // state.cashflowErrorCode = undefined;
      // state.cashflowErrorMessage = "";
    },
    [deleteBank.rejected.type]: (state, action) => {
      state.isLoadingDelete = false;
      // state.cashflowErrorCode = action.payload?.errorCode;
      // state.cashflowErrorMessage = action.payload?.message;
    },
  },
});

export const cashflowSelector = (state: RootState) => state.cashflow;
export default cashflowSlice.reducer;
