import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { capitalizeFirstLetter } from "utility/helpers/stringHelpers";
import type { RootState } from "../store";
import { NotificationItem } from "./notificationTypes";
import "moment/locale/es";

export interface NotificationState {
  isLoading: boolean;
  errorCode: string | null;
  errorMessage: string;

  deviceToken: string | null;

  hasUnreadNotifications: boolean | null;
  notifications: NotificationItem[] | null|undefined;
}

export const initialState: NotificationState = {
  isLoading: false,
  errorCode: null,
  errorMessage: "",

  deviceToken: null,

  hasUnreadNotifications: null,
  notifications: undefined,
};

export const registerUserDevice = createAsyncThunk(
  "post/registerUserDevice",
  async (argument, thunkAPI: any) => {
    try {
      const deviceToken = thunkAPI.getState().notification.deviceToken;
      if (!deviceToken) return;

      const result = await thunkAPI.extra.notification.registerUserDevice(
        "Web",
        deviceToken
      );

      return;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const unregisterUserDevice = createAsyncThunk(
  "delete/unregisterUserDevice",
  async (argument, thunkAPI: any) => {
    try {
      const deviceToken = thunkAPI.getState().notification.deviceToken;
      if (!deviceToken) return;

      try {
        await thunkAPI.extra.notification.unregisterUserDevice(deviceToken);
      } catch {
        /* empty */
      }

      return;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "get/getNotifications",
  async (argument, thunkAPI: any) => {
    try {
      const result = await thunkAPI.extra.notification.getNotifications();
      const data = result.data;

      let notifications: any[] = [];
      let hasUnreadNotifications = false;
      for (let index = 0; index < data.notifications.length; index++) {
        const element = data.notifications[index];
        const momentObj = moment(element.created_at, "YYYY-MM-DD HH:mm:ss");

        let formattedDate = momentObj.locale("es").format("DD[/]MMMM");
        const split = formattedDate.split("/");
        formattedDate = `${split[0]} de ${capitalizeFirstLetter(split[1])}`;

        notifications.push({
          title: element.title ?? "",
          description: element.body ?? "",
          read: element.read ?? false,
          date: momentObj.toISOString(),
          formattedDate: formattedDate,
          dateMilliseconds: momentObj.valueOf(),
        });
        if (!element.read) {
          hasUnreadNotifications = true;
        }
      }

      notifications = notifications.sort(
        (item1, item2) => item2.dateMilliseconds - item1.dateMilliseconds
      );

      return {
        hasUnreadNotifications,
        notifications,
      };
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  "put/markNotificationAsRead",
  async (argument, thunkAPI: any) => {
    try {
      const result = await thunkAPI.extra.notification.markNotificationAsRead(
        argument
      );
      const data = result.data;

      return argument == null;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    // ------------- //
    clearNotifications: (state, action: PayloadAction<void>) =>
      Object.assign(state, initialState),
    setDeviceToken: (state, action: PayloadAction<string>) => {
      state.deviceToken = action.payload;
    },
    setHasUnreadNotifications: (
      state,
      action: PayloadAction<boolean | null>
    ) => {
      state.hasUnreadNotifications = action.payload;
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    // registerUserDevice
    [registerUserDevice.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [registerUserDevice.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [registerUserDevice.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
    },
    // unregisterUserDevice
    [unregisterUserDevice.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [unregisterUserDevice.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [unregisterUserDevice.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
    },
    // getNotifications
    [getNotifications.fulfilled.type]: (state, action) => {
      state.notifications = action.payload.notifications;
      state.hasUnreadNotifications = action.payload.hasUnreadNotifications;
      state.isLoading = false;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [getNotifications.pending.type]: (state, action) => {
      state.notifications = undefined;
      state.hasUnreadNotifications = null;
      state.isLoading = true;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [getNotifications.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
    },
    // markNotificationAsRead
    [markNotificationAsRead.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.hasUnreadNotifications = false;
      }
      state.isLoading = false;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [markNotificationAsRead.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = "";
      state.errorCode = null;
    },
    [markNotificationAsRead.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
    },
  },
});

export const { clearNotifications, setDeviceToken, setHasUnreadNotifications } =
  notificationSlice.actions;

export const notificationSelector = (state: RootState) => state.notification;
export default notificationSlice.reducer;
