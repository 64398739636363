import {
  bindActionCreators,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  getFITours,
  getFTUTours,
  setFITours,
  setFTUTours,
} from "utility/storage/auth";
import { getUserStatus } from "utility/storage/auth";
import type { RootState } from "../store";
import { UserInfoState } from "./userInfoTypes";

export const initialState: UserInfoState = {
  availableAmount: undefined,
  disponibleOperar: undefined,
  accountState: undefined,
  totalTenencyAccount: undefined,
  info: undefined,
  tenenciaDisponible: undefined,
  account: undefined,
  transferAccount: undefined,
  isLoading: false,
  errorMessage: undefined,
  errorCode: undefined,
  contacts: undefined,
  proMode: undefined,
  profile: undefined,
  isLoadingUpdateProfile: false,
  uploadedPicture: false,
  isLoadingUpdateFaqs: false,
  isLoadingPatchSettings: false,
  faqs: undefined,

  currentAvailableExtractionPesos: null,
  currentAvailableExtractionDollars: null,
  currentAvailableExtractionPesos24: null,
  currentAvailableExtractionDollars24: null,
  currentAvailableExtractionPesos48: null,
  currentAvailableExtractionDollars48: null,

  currentCurrency: 'ARS',
};
export const getAccount = createAsyncThunk(
  "get-account",
  async (argument: void, thunkAPI: any) => {
    try {
      let res;
      // console.log(getUserStatus());
      if (getUserStatus() === "MANUAL-VERIFICATION") {
        res = null;
      } else {
        res = await thunkAPI.extra.userInfo.getAccount();
      }

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getActivityNotification = createAsyncThunk(
  "get-activity-notification",
  async (argument: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.userInfo.getActivityNotification();

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getProfile = createAsyncThunk(
  "get-profile",
  async (argument: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.userInfo.getProfile();

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const patchProfile = createAsyncThunk(
  "patch-profile",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.userInfo.patchProfile(data);

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const patchNickname = createAsyncThunk(
  "patch-nickname",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.userInfo.patchNickname(data);

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const patchPicture = createAsyncThunk(
  "patch-picture",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.userInfo.patchPicture(data);

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const patchSettings = createAsyncThunk(
  "patch-settings",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.userInfo.patchSettings(data);

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getFaqs = createAsyncThunk(
  "get-faqs",
  async (_: void, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.userInfo.getFaqs();

      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    setProMode: (state, action) => {
      localStorage.setItem("promode", action?.payload);
      state.proMode = action?.payload;
    },
    // ------------- //
    cleanErrorState: (state) => {
      state.errorCode = undefined;
      state.errorMessage = "";
    },
    forceFTU: (state) => {
      state.accountState = "FirstTimeUser";
    },
    cleanUploadedPictureFlag: (state) => {
      state.uploadedPicture=false;
    },
    clearUserInfoStore: (state) => {
      Object.assign(state, initialState);
    },
    setCurrentCurrency: (state, action: PayloadAction<string>) => {
      state.currentCurrency = action.payload;
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    [getAccount.fulfilled.type]: (state, action) => {
      if (action.payload) {
        const data = action?.payload?.data;
        // DISPONIBLE EXTRAER INMEDIATO
        const disponibleExtraerPesosIndex =
          data.disponibleExtraer.inmediato.findIndex(
            (item) => item.monedaDesc == "Pesos"
          );
        const disponibleExtraerDollarsIndex =
          data.disponibleExtraer.inmediato.findIndex(
            (item) => item.monedaDesc == "Dolares"
          );

        const disponibleExtraerPesosString =
          disponibleExtraerPesosIndex >= 0
            ? data.disponibleExtraer.inmediato[disponibleExtraerPesosIndex]
                .importe ?? 0
            : 0;
        const disponibleExtraerDollarsString =
          disponibleExtraerDollarsIndex >= 0
            ? data.disponibleExtraer.inmediato[disponibleExtraerDollarsIndex]
                .importe ?? 0
            : 0;

        const disponibleExtraerPesos = disponibleExtraerPesosString
          ? parseFloat(disponibleExtraerPesosString)
          : 0;
        const disponibleExtraerDollars = disponibleExtraerDollarsString
          ? parseFloat(disponibleExtraerDollarsString)
          : 0;

        // DISPONIBLE EXTRAER 24HS
        const disponibleExtraerPesos24Index = data.disponibleExtraer[
          "24Hs"
        ].findIndex((item) => item.monedaDesc == "Pesos");
        const disponibleExtraerDollars24Index = data.disponibleExtraer[
          "24Hs"
        ].findIndex((item) => item.monedaDesc == "Dolares");

        const disponibleExtraerPesos24String =
          disponibleExtraerPesos24Index >= 0
            ? data.disponibleExtraer["24Hs"][disponibleExtraerPesos24Index]
                .importe ?? 0
            : 0;
        const disponibleExtraerDollars24String =
          disponibleExtraerDollars24Index >= 0
            ? data.disponibleExtraer["24Hs"][disponibleExtraerDollars24Index]
                .importe ?? 0
            : 0;

        const disponibleExtraerPesos24 = disponibleExtraerPesos24String
          ? parseFloat(disponibleExtraerPesos24String)
          : 0;
        const disponibleExtraerDollars24 = disponibleExtraerDollars24String
          ? parseFloat(disponibleExtraerDollars24String)
          : 0;

        // DISPONIBLE EXTRAER 48HS
        const disponibleExtraerPesos48Index = data.disponibleExtraer[
          "48Hs"
        ].findIndex((item) => item.monedaDesc == "Pesos");
        const disponibleExtraerDollars48Index = data.disponibleExtraer[
          "48Hs"
        ].findIndex((item) => item.monedaDesc == "Dolares");

        const disponibleExtraerPesos48String =
          disponibleExtraerPesos48Index >= 0
            ? data.disponibleExtraer["48Hs"][disponibleExtraerPesos48Index]
                .importe ?? 0
            : 0;
        const disponibleExtraerDollars48String =
          disponibleExtraerDollars48Index >= 0
            ? data.disponibleExtraer["48Hs"][disponibleExtraerDollars48Index]
                .importe ?? 0
            : 0;

        const disponibleExtraerPesos48 = disponibleExtraerPesos48String
          ? parseFloat(disponibleExtraerPesos48String)
          : 0;
        const disponibleExtraerDollars48 = disponibleExtraerDollars48String
          ? parseFloat(disponibleExtraerDollars48String)
          : 0;

        state.currentAvailableExtractionPesos = disponibleExtraerPesos;
        state.currentAvailableExtractionDollars = disponibleExtraerDollars;
        state.currentAvailableExtractionPesos24 = disponibleExtraerPesos24;
        state.currentAvailableExtractionDollars24 = disponibleExtraerDollars24;
        state.currentAvailableExtractionPesos48 = disponibleExtraerPesos48;
        state.currentAvailableExtractionDollars48 = disponibleExtraerDollars48;

        if (
          action?.payload?.data?.tenenciaTotal[0].importe === 0 &&
          action?.payload?.data?.tenenciaTotal[1].importe === 0 &&
          action?.payload?.data?.disponibleOperar?.inmediato[0]?.importe ===
            0 &&
          action?.payload?.data?.disponibleOperar?.inmediato[1]?.importe === 0
        ) {
          state.accountState = "FirstTimeUser";
        } else if (
          action?.payload?.data?.tenenciaTotal[0].importe ===
            action?.payload?.data?.disponibleOperar?.inmediato[0]?.importe &&
          action?.payload?.data?.tenenciaTotal[1].importe ===
            action?.payload?.data?.disponibleOperar?.inmediato[1]?.importe
        ) {
          state.accountState = "HasNotInvested";
        } else {
          state.accountState = "Invested";
          // state.accountState ="HasNotInvested";
        }
        // state.accountState =
        //   action?.payload?.data?.tenenciaTotal?.length !== 0
        //     ? "Invested"
        //     : action?.payload?.data?.disponibleOperar?.inmediato.length !== 0
        //     ? "HasNotInvested"
        //     : "FirstTimeUser";
        state.account = { ars: [], usd: [], legacy: [] };
        state.totalTenencyAccount = { ars: {}, usd: {} };
        state.tenenciaDisponible = action?.payload?.data?.tenenciaDisponible;
        state.disponibleOperar = action?.payload?.data?.disponibleOperar;
        const disponibleInmediato =
          action?.payload?.data?.disponibleOperar?.inmediato;

        state.availableAmount = {
          ars: Number(
            disponibleInmediato[
              disponibleInmediato?.findIndex((item) => item.codMoneda === 1)
            ]?.importe
          ),
          usd: Number(
            disponibleInmediato[
              disponibleInmediato?.findIndex((item) => item.codMoneda === 3)
            ]?.importe
          ),
        };

        // state.account = action?.payload?.data;
        action?.payload?.data?.tenenciaDisponible?.forEach((item) => {
          if (item.codMoneda == 1 ) {
            state?.account?.ars?.push(item);
          } else {
            //Para "Dolares" en listado activos y al ingresar
            if (
              item?.instrumentType === "MON" &&
              Number(item?.codMoneda) === 3
             
            ) {
              item.tenenciaInmediata = disponibleExtraerDollars;
              item.tenencia24 = disponibleExtraerDollars24;
              item.tenencia48 = disponibleExtraerDollars48;
            }
            state?.account?.usd?.push(item);
          }

            state?.account?.legacy?.push(item);
        });
        action?.payload?.data?.tenenciaTotal?.forEach((item) => {
          if (item.codMoneda == 1) {
            state.totalTenencyAccount.ars = item;
          } else {
            state.totalTenencyAccount.usd = item;
          }
        });

        state?.account?.ars?.sort((a, b) => b.tenencia48 - a.tenencia48);
        state?.account?.usd?.sort((a, b) => b.tenencia48 - a.tenencia48);
        if (getUserStatus() !== "MANUAL-VERIFICATION") {
          if (!getFITours() && !getFTUTours()) {
            if (state.accountState === "FirstTimeUser") {
              setFITours([]);
              setFTUTours([
                "home",
                "profile",
                "fundPro",
                "fundLite",
                "instrument",
                "dolarmep",
                "wallet",
                "walletCurrency",
                "opportunities",
              ]);
            } else if (state.accountState === "HasNotInvested") {
              setFTUTours(["home"]);
              setFITours([]);
            } else {
              setFTUTours([]);
              setFITours([]);
            }
          }
        }
        state.info = action?.payload?.data?.info;
      }
      state.isLoading = false;
      state.errorMessage = null;
      state.errorCode = null;
    },
    [getAccount.pending.type]: (state, action) => {
      state.account = undefined;
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getAccount.rejected.type]: (state, action) => {
      state.account = undefined;
      state.isLoading = false;
      if (action?.payload?.errorCode === "")
        state.errorMessage = action.payload?.message;
      state.errorCode = action.payload?.errorCode;
    },

    [getActivityNotification.fulfilled.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getActivityNotification.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getActivityNotification.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },

    [getProfile.fulfilled.type]: (state, action) => {
      state.profile = action?.payload?.data;
      state.isLoading = false;
      state.errorMessage = null;
    },
    [getProfile.pending.type]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getProfile.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action?.payload?.errorCode;
    },
    [patchProfile.fulfilled.type]: (state, action) => {
      state.isLoadingUpdateProfile = false;
      state.errorMessage = null;
    },
    [patchProfile.pending.type]: (state, action) => {
      state.isLoadingUpdateProfile = true;
      state.errorMessage = null;
    },
    [patchProfile.rejected.type]: (state, action) => {
      state.isLoadingUpdateProfile = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [patchNickname.fulfilled.type]: (state, action) => {
      state.isLoadingUpdateProfile = false;
      state.errorMessage = null;
    },
    [patchNickname.pending.type]: (state, action) => {
      state.isLoadingUpdateProfile = true;
      state.errorMessage = null;
    },
    [patchNickname.rejected.type]: (state, action) => {
      state.isLoadingUpdateProfile = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [patchPicture.fulfilled.type]: (state, action) => {
      // state.profile = action?.payload?.data;
      state.isLoadingUpdateProfile = false;
      state.uploadedPicture=true;
      state.errorMessage = null;
    },
    [patchPicture.pending.type]: (state, action) => {
      state.isLoadingUpdateProfile = true;
      state.errorMessage = undefined;
      state.uploadedPicture=false;
    },
    [patchPicture.rejected.type]: (state, action) => {
      state.isLoadingUpdateProfile = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
      state.uploadedPicture=false;
    },
    [patchSettings.fulfilled.type]: (state, action) => {
      // state.profile = action?.payload?.data;
      state.isLoadingPatchSettings = false;
      state.errorMessage = null;
    },
    [patchSettings.pending.type]: (state, action) => {
      state.isLoadingPatchSettings = true;
      state.errorMessage = null;
    },
    [patchSettings.rejected.type]: (state, action) => {
      state.isLoadingPatchSettings = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
    [getFaqs.fulfilled.type]: (state, action) => {
      state.faqs = action?.payload?.data;
      state.isLoadingUpdateFaqs = false;
      state.errorMessage = null;
    },
    [getFaqs.pending.type]: (state, action) => {
      state.isLoadingUpdateFaqs = true;
      state.errorMessage = null;
    },
    [getFaqs.rejected.type]: (state, action) => {
      state.isLoadingUpdateFaqs = false;
      state.errorMessage = action.payload?.message;
      state.errorCode = action.payload.errorCode;
    },
  },
});

export const {forceFTU, cleanUploadedPictureFlag,cleanErrorState, setProMode, clearUserInfoStore, setCurrentCurrency} =
  userInfoSlice.actions;
export const userInfoSelector = (state: RootState) => state.userInfo;
export default userInfoSlice.reducer;
