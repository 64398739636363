import React from "react";
import { components, StepType } from "@reactour/tour";
import { StylesObj } from "@reactour/tour/dist/styles";

const toursSteps = {
  wallet: [
    {
      selector: '[data-tour="wallet-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Tu patrimonio</div>
            <span className="description">
              {" "}
              Visualizá tu dinero y tus inversiones en pesos y en dólares.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="wallet-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">¡Empezá a invertir hoy mismo!</div>
            <span className="description"> Ingresá dinero a tu cuenta.</span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  opportunities: [
    {
      selector: '[data-tour="op-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Conocé tus opciones</div>
            <span className="description">
              {" "}
              Explorá las distintas oportunidades del mercado
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="op-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Cambiá a modo Pro</div>
            <span className="description">
              {" "}
              ¡Desde tu perfil, podrás cambiar al Modo Pro cuando quieras!
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  profile: [
    {
      selector: '[data-tour="profile-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Modo Lite o Pro</div>
            <span className="description">
              {" "}
              Configurá la vista que más se adapte a tu perfil de inversión.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  dolarmep: [
    {
      selector: '[data-tour="mep-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Compra o venta</div>
            <span className="description">
              {" "}
              Elegí si querés comprar o vender Dólar MEP.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="mep-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Ingresá el monto para operar dólar MEP</div>
            <span className="description">
              {" "}
              También podés ingresar tu saldo total.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  homeFirstTimeUser: [
    {
      selector: '[data-tour="homeFirstTimeUser-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Hacé swipe y mirá tu dinero.</div>
            <span className="description">
              Podés visualizar tus pesos disponibles, tus pesos invertidos y tus
              dólares.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="homeFirstTimeUser-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Ingresá o retirá dinero</div>
            <span className="description">
              Podés transferir pesos o dólares. Recordá que siempre tiene que
              ser desde una cuenta a tu nombre.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="homeFirstTimeUser-step-3"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Invertí y ahorrá</div>
            <span className="description">
              Explorá todas las alternativas de inversión del mercado.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  homeHasNotInvested: [
    {
      selector: '[data-tour="homeHasNotInvested-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Visualizá tus inversiones</div>
            <span className="description">
              Desde la sección de cartera, podrás consultar todas tus
              inversiones realizadas.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  // homeInvested: [
  //   {
  //     selector: '[data-tour="homeInvested-step-1"]',
  //     content: (
  //       <div className="popover-content">
  //         <div className="avatar" />
  //         <div className="info">
  //           <div className="title">
  //             Hacé swipe y mirá tus pesos invertidos y dólares
  //           </div>
  //           <span className="description">
  //             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
  //             eiusmod tempor.
  //           </span>
  //         </div>
  //       </div>
  //     ),
  //   },
  // ] as StepType[],
  marketStockActionPro: [
    {
      selector: '[data-tour="marketStockActionPro-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Precio unitario</div>
            <span className="description">
              Visualizá el precio del instrumento en tiempo real.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="marketStockActionPro-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Puntas</div>
            <span className="description">
              Los precios que se están operando en el mercado para compra y
              venta.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="marketStockActionPro-step-3"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Precios</div>
            <span className="description">
              Tenés dos opciones: comprá o vendé a precio de mercado, o definí
              un precio límite para tu inversión.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  marketStockBonosPro: [
    {
      selector: '[data-tour="marketStockBonosPro-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Precio unitario</div>
            <span className="description">
              Visualizá el precio del instrumento en tiempo real.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="marketStockBonosPro-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Puntas</div>
            <span className="description">
              Los precios que se están operando en el mercado para compra y
              venta.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="marketStockBonosPro-step-3"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Precios</div>
            <span className="description">
              Tenés dos opciones: comprá o vendé a precio de mercado, o definí
              un precio límite para tu inversión.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  marketStockCedearsPro: [
    {
      selector: '[data-tour="marketStockCedearsPro-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Precio unitario</div>
            <span className="description">
              Visualizá el precio del instrumento en tiempo real.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="marketStockCedearsPro-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Puntas</div>
            <span className="description">
              Los precios que se están operando en el mercado para compra y
              venta.
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="marketStockCedearsPro-step-3"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Precios</div>
            <span className="description">
              Tenés dos opciones: comprá o vendé a precio de mercado, o definí
              un precio límite para tu inversión.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  marketStockFundsPro: [
    {
      selector: '[data-tour="marketStockFundsPro-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Valor de la cuotaparte</div>
            <span className="description">
              Visualizá el precio del fondo al cierre del día anterior hábil..
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="marketStockFundsPro-step-2"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Importe</div>
            <span className="description">
              Ingresá el importe que deseas suscribir al fondo común de
              inversión.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  marketStockFundsDetailsPro: [
    {
      selector: '[data-tour="marketStockFundsDetailsPro-step-1"]',
      content: (
        <div
          className="popover-content"
          style={{ padding: "0 !important", margin: "0 !important" }}
        >
          <div className="info">
            <div className="title">Valor de la cuotaparte</div>
            <span className="description">
              Visualizá el precio del fondo al cierre del día anterior hábil.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  marketStockFundsDetailsLite: [
    {
      selector: '[data-tour="marketStockFundsDetailsLite-step-1"]',
      content: (
        <div
          className="popover-content"
          style={{ padding: "0 !important", margin: "0 !important" }}
        >
          <div className="info">
            <div className="title">Valor de la cuotaparte</div>
            <span className="description">
              Visualizá el precio del fondo al cierre del día anterior hábil.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
  walletTourCurrency: [
    {
      selector: '[data-tour="walletTourCurrency-step-1"]',
      content: (
        <div className="popover-content">
          <div className="info">
            <div className="title">Tu saldo en el tiempo</div>
            <span className="description">
              Mirá el dinero que vas a tener disponible en tu cuenta en 24hs y
              48hs hábiles.
            </span>
          </div>
        </div>
      ),
    },
  ] as StepType[],
};

const opositeSide = {
  top: "bottom",
  bottom: "top",
  right: "left",
  left: "right",
};

export const getActiveTourSteps = (tourMode: string): StepType[] => {
  return toursSteps[tourMode] ?? [{ selector: "", content: <></> }];
};

export const doArrow = (position, verticalAlign, horizontalAlign) => {
  if (!position || position === "custom") {
    return {};
  }

  const width = 16;
  const height = 12;
  const color = "white";
  const isVertical = position === "top" || position === "bottom";
  const spaceFromSide = 10;
  const obj = {
    [isVertical ? "borderLeft" : "borderTop"]: `${
      width / 2
    }px solid transparent`, // CSS Triangle width
    [isVertical ? "borderRight" : "borderBottom"]: `${
      width / 2
    }px solid transparent`, // CSS Triangle width
    [`border${position[0].toUpperCase()}${position.substring(
      1
    )}`]: `${height}px solid ${color}`, // CSS Triangle height
    [isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
      height + spaceFromSide, // space from side
    [opositeSide[position]]: -height + 2,
  };

  return {
    "&::after": {
      content: "''",
      width: 0,
      height: 0,
      position: "absolute",
      ...obj,
    },
  };
};

export const getNextButton = ({
  Button,
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
}) => {
  const last = currentStep === stepsLength - 1;
  return (
    <button
      className="popover-button next"
      onClick={() => {
        if (last) setIsOpen(false);
        else setCurrentStep((s) => (s === last ? 0 : s + 1));
      }}
    >
      {last ? "¡Listo!" : "SIGUIENTE"}
    </button>
  );
};

export const getPrevButton = ({
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
}) => {
  // const first = currentStep === 0
  // const next = first ? stepsLength - 1 : currentStep - 1
  if (stepsLength === 1) return;
  return (
    <button
      className="popover-button"
      onClick={() => {
        setIsOpen(false);
        setCurrentStep(0);
      }}
    >
      OMITIR
    </button>
  );
};

export const Badge = ({ children }: any): any => {
  return (
    <components.Badge
      styles={{
        badge: (base) => ({
          ...base,
          background: "none",
          top: 10,
          right: 10,
          boxShadow: "none",
          textAlign: "right",
        }),
      }}
    >
      <span className="pages-indicator">{children}</span>
    </components.Badge>
  );
};

export const tourStyles = {
  maskWrapper: (base) => ({
    ...base,
    color: "#1F65FF80",
  }),
  maskArea: (base) => ({ ...base, rx: 10 }),
  highlightedArea: (base) => ({ ...base, rx: 10 }),
  popover: (base, state) => {
    return {
      ...base,
      borderRadius: 10,
      padding: 20,
      ...doArrow(state?.position, state?.verticalAlign, state?.horizontalAlign),
    };
  },
  dot: (base) => {
    return { ...base, display: "none" };
  },
} as StylesObj;

export const getBadgeContent = ({ totalSteps, currentStep }) => {
  return `${currentStep + 1}/${totalSteps}`;
};
