import { axiosJsonServerInstance, axiosWalletInstance } from "./axiosInstances";

export default {
  getLastMovements: (ticker) =>
    axiosWalletInstance.get(
      `/movements?wallet=MarketHolding&currencies=${ticker}`
    ),
  getMovementsByInstrument: (ticker) =>
    axiosWalletInstance.get(
      `/movements?wallet=MarketHolding&currencies=${ticker}`
    ),
  getWalletPerformance: (actions, currency) =>
    axiosWalletInstance.get(
      `/movements?wallet=MarketHolding&actions=${actions}&currencies=${currency}`
    ),
    getFilteredMovements: (wallet, date_start, date_end, currency, actions) =>
    axiosWalletInstance.get(
      `/movements?date_start=${date_start}&date_end=${date_end}${
          wallet ? `&wallets=${wallet}` : ''
      }${currency ? `&currencies=${currency}` : ''}${
          actions ? `&actions=${actions}` : ''
      }`,
  ),

    getInfoInstrumentProfit: (ticker: string) =>
    axiosWalletInstance.get(`profits/${ticker}`),

    getOneInfoInstrumentInvested: (ticker: string, date_start: string, date_end: string) =>
    axiosWalletInstance.get(`/balance/historic/wallet/MarketHolding/${ticker}/${date_start}/${date_end}`),

    getWalletProfit: (date_start: string, date_end: string) => 
    axiosWalletInstance.get(`/profits_portfolio/${date_start}/${date_end}`),
    
    getProfitPortfolio : (start_date: string , end_date: string) => 
    axiosWalletInstance.get(`/profits_portfolio/${start_date}/${end_date}`),
};