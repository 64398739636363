import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
// import './index.css';
import BanzaApp from "./BanzaApp";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { TourProvider } from "@reactour/tour";
import {
  Badge,
  getBadgeContent,
  getNextButton,
  getPrevButton,
  tourStyles,
} from "utility/helpers/tourManager";
import { ThemeProvider } from "styled-components";
import { theme } from "./utility/theme";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <TourProvider
          steps={[]}
          styles={tourStyles}
          nextButton={getNextButton}
          prevButton={getPrevButton}
          badgeContent={getBadgeContent}
          components={{ Badge }}
          showCloseButton={false}
        >
          <ThemeProvider theme={theme}>
            <BanzaApp />
          </ThemeProvider>
        </TourProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
