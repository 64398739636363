// import InvestLayout from "modules/investment/layout/InvestLayout";
// import InvestmentPage from "modules/investment/pages/InvesmentPage";
import React, { useRef } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useAppSelector } from "../store/storeHooks";
import { RootState } from "../store/store";

const HomePage = React.lazy(() => import("../components/pages/Home"));
const Investments = React.lazy(() => import("../components/pages/Invest"));
const SecondPage = React.lazy(
  () => import("../components/pages/SecondPage/SecondPage")
);
const Login = React.lazy(() => import("../components/pages/Login"));
const Testing = React.lazy(() => import("../components/pages/Testing"));
const Email = React.lazy(() => import("../components/pages/Register/Email"));
const Code = React.lazy(() => import("../components/pages/Register/Code"));
const EmailValidated = React.lazy(
  () => import("../components/pages/Register/EmailValidatedSuccess")
);
const CreatePassword = React.lazy(
  () => import("../components/pages/Register/CreatePassword")
);
const Onboarding = React.lazy(() => import("../components/pages/Onboarding"));
const CheckYourEmail = React.lazy(
  () => import("../components/pages/Register/CheckYourEmail")
);
const ForgotYourPw = React.lazy(
  () => import("../components/pages/Register/ForgotYourPw")
);
const EmailRegistred = React.lazy(
  () => import("../components/pages/Register/EmailRegistered")
);

const SuccessfulPurchase = React.lazy(
  () => import("../components/pages/SuccessfulPurchase")
);

const ErrorPurchase = React.lazy(
  () => import("../components/pages/ErrorPurchase")
);
const MyWallet = React.lazy(
  () => import ("../components/pages/Invest/Wallet/myWallet/myWallet")
)
const HowUseYourMoney = React.lazy(
  () => import("../components/pages/HowUseYourMoney")
);
const PendingValidation = React.lazy(
  () => import("../components/pages/Onboarding/PendingValidation")
);
const StockDetail = React.lazy(() => import("../components/pages/StockDetail"));
const PackDetail = React.lazy(() => import("../components/pages/PackDetail"));
const FundDetail = React.lazy(() => import("../components/pages/FundDetail"));
const FundDetailMP = React.lazy(
  () => import("../components/pages/FundDetailMP")
);
const ActionDetail = React.lazy(
  () => import("../components/pages/ActionDetail")
);
const CedearDetail = React.lazy(
  () => import("../components/pages/CedearDetail")
);
const BondDetail = React.lazy(() => import("../components/pages/BondDetail"));
const ActionTransaction = React.lazy(
  () => import("../components/pages/ActionTransaction")
);
const CedearTransaction = React.lazy(
  () => import("../components/pages/CedearTransaction")
);
const FundTransaction = React.lazy(
  () => import("../components/pages/FundTransaction")
);
const BondTransaction = React.lazy(
  () => import("../components/pages/BondTransaction")
);
const Actions = React.lazy(() => import("../components/pages/Actions"));
const Cedears = React.lazy(() => import("../components/pages/Cedears"));
const Bonds = React.lazy(() => import("../components/pages/Bonds"));
const Funds = React.lazy(() => import("../components/pages/Funds"));
const Cart = React.lazy(() => import("../components/pages/Cart"));
const DolarMep = React.lazy(() => import("../components/pages/DolarMep"));
const DolarMepSlider = React.lazy(() => import("../components/pages/DolarMep/DolarMepSlider"))
const Welcome = React.lazy(() => import("../components/pages/Welcome"));
const Bookmarks = React.lazy(() => import("../components/pages/Bookmarks"));
const Notifications = React.lazy(
  () => import("../components/pages/Notifications")
);
const DNIValidation = React.lazy(
  () => import("../components/pages/Onboarding/DNIValidation")
);
const Profile = React.lazy(() => import("../components/pages/Profile"));
const ProfilePersonalData = React.lazy(
  () => import("../components/pages/Profile/ProfilePersonalData")
);

const ProfileFAQ = React.lazy(
  () => import("../components/pages/Profile/ProfileFAQ")
);

const ProfileFAQTopicQuestion = React.lazy(
  () =>
    import(
      "../components/pages/Profile/ProfileFAQ/ProfileFAQTopic/ProfileFAQTopicQuestion"
    )
);
const ProfileFAQTopic = React.lazy(
  () => import("../components/pages/Profile/ProfileFAQ/ProfileFAQTopic")
);

const ProfileLaw = React.lazy(
  () => import("../components/pages/Profile/ProfileLaw")
);
const ProfilePicture = React.lazy(
  () => import("../components/pages/Profile/ProfilePicture")
);

const ProfileHelp = React.lazy(
  () => import("../components/pages/Profile/ProfileHelp")
);

const ProfileLawSection = React.lazy(
  () => import("../components/pages/Profile/ProfileLaw/ProfileLawSection")
);

const ProfileSettingsChangePassword = React.lazy(
  () => import("../components/pages/Profile/ProfileSettingsChangePassword")
);

const Pesos = React.lazy(() => import("../components/pages/MiCartera/Pesos"));
const WalletInstrument = React.lazy(
  () => import("../components/pages/MiCartera/WalletInstrument")
);
const WalletLastMovements = React.lazy(
  () => import("../components/pages/MiCartera/WalletLastMovements")
);
const WalletPerformance = React.lazy(
  () => import("../components/pages/MiCartera/WalletPerformance")
);
const InstrumentDetail = React.lazy(
  () => import("../components/pages/MiCartera/InstrumentDetail")
);
const InvestTyC = React.lazy(
  () => import("../components/pages/MiCartera/InvestTyC")
);
const CreateRules = React.lazy(
  () => import("../components/pages/MiCartera/CreateRules")
);

/**CASHIN-CASHOUT */
const EditTransferContact = React.lazy(
  () => import("../components/pages/EditTransferContact")
);
const CashOut = React.lazy(() => import("../components/pages/CashOut"));
const CashOutAmount = React.lazy(
  () => import("../components/pages/CashOut/CashOutAmount")
);
const CashOutConfirm = React.lazy(
  () => import("../components/pages/CashOut/CashOutConfirm")
);
const CashIn = React.lazy(() => import("../components/pages/CashIn"));
/** */
const GlobalCart = React.lazy(() => import("../components/pages/GlobalCart"));

const Activity = React.lazy(() => import("../components/pages/Activity"));

const NewsVideoPage = React.lazy(
  () => import("../components/pages/NewsVideoPage")
);

export const ProtectedRoutes = () => {
  const location = useLocation();
  const nodeRef = useRef();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        timeout={700}
        nodeRef={nodeRef}
      >
        <Switch location={location}>
          <Route exact path="/onboarding" component={Onboarding} />
          <Route
            exact
            path="/onboarding/validation-pending"
            component={PendingValidation}
          />
          <Redirect to="/onboarding" />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
export const PrivateRoutes = () => {
  const location = useLocation();
  const nodeRef = useRef();

  return (
    <TransitionGroup>
      {/*
      This is no different than other usage of
      <CSSTransition>, just make sure to pass
      `location` to `Switch` so it can match
      the old location as it animates out.
    */}
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        timeout={700}
        nodeRef={nodeRef}
      >
        <Switch location={location}>
          <Route exact path="/welcome" component={Welcome} />

          <Route exact path="/opportunities/bookmarks" component={Bookmarks} />
          <Route
            exact
            path="/opportunities/stock:ticker"
            component={StockDetail}
          />

          <Route
            exact
            path="/opportunities/pack:ticker"
            component={PackDetail}
          />
          {/**MODO PRO **/}
          <Route exact path="/opportunities/actions">
            <ProtectedMPRoute>
              <Actions />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/bonds">
            <ProtectedMPRoute>
              <Bonds />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/funds">
            <ProtectedMPRoute>
              <Funds />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/cedears">
            <ProtectedMPRoute>
              <Cedears />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/mpfunds:ticker">
            <ProtectedMPRoute>
              <FundDetailMP />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/actions:ticker">
            <ProtectedMPRoute>
              <ActionDetail />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/cedears:ticker">
            <ProtectedMPRoute>
              <CedearDetail />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/bonds:ticker">
            <ProtectedMPRoute>
              <BondDetail />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/actions:ticker/transaction">
            <ProtectedMPRoute>
              <ActionTransaction />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/cedears:ticker/transaction">
            <ProtectedMPRoute>
              <CedearTransaction />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/mpfund:ticker/transaction">
            <ProtectedMPRoute>
              <FundTransaction />
            </ProtectedMPRoute>
          </Route>
          <Route exact path="/opportunities/bonds:ticker/transaction">
            <ProtectedMPRoute>
              <BondTransaction />
            </ProtectedMPRoute>
          </Route>

          {/** END MODO PRO **/}
          <Route
            exact
            path="/opportunities/fund:ticker"
            component={FundDetail}
          />

          <Route exact path="/confirm-order" component={Cart} />
          <Route exact path="/investments" component={Investments} />
          <Route exact path="/myWallet" component={MyWallet} />
          <Route
            exact
            path="/wallet/walletperformance/:currency(1|3)"
            component={WalletPerformance}
          />
          <Route exact path="/wallet/:currency(1|3)" component={Pesos} />
          <Route exact path="/wallet/:ticker" component={WalletInstrument} />
          <Route
            exact
            path="/wallet/:currency/lastmovements"
            component={WalletLastMovements}
          />
          <Route
            exact
            path="/wallet/:currency(1|3)/lastmovements:ticker"
            component={InstrumentDetail}
          />
          <Route
            exact
            path="/wallet/:currency(1|3)/allow"
            component={InvestTyC}
          />
          <Route exact path="/wallet/:ticker/rules" component={CreateRules} />
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/videos" component={NewsVideoPage} />

          {/* <InvestLayout>
            <Route exact path="/investments2" component={Wallet} />
          </InvestLayout> */}

          <Route
            exact
            path="/successful-purchase"
            component={SuccessfulPurchase}
          />
          <Route exact path="/error-purchase" component={ErrorPurchase} />
          <Route exact path="/market-home" component={HowUseYourMoney} />
          <Route
            exact
            path="/onboarding/validation-dni"
            component={DNIValidation}
          />
          <Route exact path="/dolar-mep" component={DolarMep} />
          <Route exact path="/dolar-mep-slider" component={DolarMepSlider} />
          <Route exact path="/profile" component={Profile} />
          <Route
            exact
            path="/profile/personal-data"
            component={ProfilePersonalData}
          />
          <Route
            exact
            path="/profile/faq/:topic/:question"
            component={ProfileFAQTopicQuestion}
          />
          <Route exact path="/profile/faq/:topic" component={ProfileFAQTopic} />
          <Route exact path="/profile/faq" component={ProfileFAQ} />
          <Route exact path="/profile/law" component={ProfileLaw} />
          <Route exact path="/profile/picture" component={ProfilePicture} />
          <Route exact path="/profile/help" component={ProfileHelp} />
          <Route
            exact
            path="/profile/law/:section"
            component={ProfileLawSection}
          />
          <Route
            exact
            path="/profile/change-password"
            component={ProfileSettingsChangePassword}
          />
          <Route exact path="/notifications" component={Notifications} />
          <Route exact path="/contact" component={EditTransferContact} />
          <Route
            exact
            path="/cashout/:user/:currency"
            component={CashOutAmount}
          />
          <Route exact path="/cashout/confirm" component={CashOutConfirm} />
          <Route exact path="/cashout" component={CashOut} />
          <Route exact path="/cashin" component={CashIn} />
          <Route
            exact
            path="/global-cart"
            // component={GlobalCart}
            component={(props: any) => (
              <GlobalCart key={props?.location?.state?.deletedOrderId ?? ""} />
            )}
          />
          <Route exact path="/activity" component={Activity} />
          <Route
            exact
            path="/register/email-validated-success"
            component={EmailValidated}
          />
          <Redirect to="/home" />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export const PublicRoutes = () => {
  const location = useLocation();
  const nodeRef = useRef();

  return (
    <TransitionGroup>
      {/*
      This is no different than other usage of
      <CSSTransition>, just make sure to pass
      `location` to `Switch` so it can match
      the old location as it animates out.
    */}
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        timeout={300}
        nodeRef={nodeRef}
      >
        <Switch location={location}>
          <Route exact path="/login" component={Login} />
          <Route exact path="/second-page" component={SecondPage} />
          <Route exact path="/testing" component={Testing} />
          <Route exact path="/register/email" component={Email} />
          <Route exact path="/register/code" component={Code} />
          <Route exact path="/register/password" component={CreatePassword} />
          <Route
            exact
            path="/register/forgot-your-password"
            component={ForgotYourPw}
          />
          <Route
            exact
            path="/register/email-already-registered"
            component={EmailRegistred}
          />
          <Route
            exact
            path="/register/check-your-email"
            component={CheckYourEmail}
          />
          <Route
            exact
            path="/register/email-validated-success"
            component={EmailValidated}
          />
          <Redirect to="/login" />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export const ProtectedMPRoute = ({ children }: any) => {
  const proMode = useAppSelector((state: RootState) => state.userInfo.proMode);

  if (proMode === undefined) return <></>;
  if (!proMode) return <Redirect to="/home" />;
  return children;
};
