import * as React from "react";
import qrImage from "../../../assets/img/qr-banza.png";
import cameraImage from "../../../assets/img/icon-camara.svg";
import logoBanza from "../../../assets/img/logo-banza.png";
import styles from "./MaintenancePage.module.scss";

const MaintenancePage = (props: MaintenancePageProps) => {
  const { 
  title,
  message, 
  } = props;

  return (
    <div className={styles["MaintenanceContainer"]}>
      <img src={logoBanza} alt="logo banza" className={styles.logoBanza} />
    <div className={styles["ImageContainer"]}>
       <img src={qrImage} alt="QR Code" />
       <button className={styles["buttonCamara"]}>
        Escaneáme <img src={cameraImage} alt="camera icon"/>
        </button>
    </div>
    <div className={styles.TextContainer}>
        <h4 className={styles.titleTypography}>
          {title}
        </h4>
        <p className={styles.descriptionTypography}>
          {message}
        </p>
      </div>
  </div>
  );
};

MaintenancePage.defaultProps = {};

type MaintenancePageProps = {
  title: string;
  message: string;
};
export default MaintenancePage;
