import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { toCurrencyString } from "utility/helpers/stringHelpers";
import type { RootState } from "../store";
import { ContentState } from "./contentTypes";
import { object } from "yup";

export const initialState: ContentState = {
    allNewsVideos: [],
    isLoading: false,
    errorMessage: undefined,
    errorCode: undefined,
  };

    // ------------- //
    // Async Actions //
    // ------------- //

  export const getInfoContent = createAsyncThunk(
    "info-content",
    async (argument: void, thunkAPI: any) => {
      try {
        const result = await thunkAPI.extra.content.getInfoContent();
        // Ordeno para que el último subido, sea el 1ero en mostrarse
        const orderedVideos = result.data?.sort((a, b) => b.order - a.order);
        return orderedVideos;
      } catch (err: any) {
        if (!err.response) {
          throw err;
        }
        return thunkAPI.rejectWithValue(err.response.data);
      }
    }
  );


    // ----- //
    // Slice //
    // ----- //


    export const contentSlice = createSlice({
        name: "content",
        initialState,
        reducers: { clearApiContent: (state) => Object.assign(state, initialState), },
        extraReducers: {
            // ------------- //
            // Sync Reducers //
            // ------------- //
          [getInfoContent.fulfilled.type]: (state, action) => {
            state.allNewsVideos = action.payload;
            state.isLoading = false;
            state.errorMessage = '';
            state.errorCode = null;
          },
          [getInfoContent.pending.type]: (state, action) => {
            state.isLoading = true;
            state.errorMessage = '';
            state.allNewsVideos = null;
            state.errorCode = undefined;
          },
          [getInfoContent.rejected.type]: (state, action) => {
            state.allNewsVideos = null;
            state.isLoading = false;
            state.errorMessage = action.payload?.error_messages[0];
            // state.errorCode = action.payload.errorCode;
          },
        },
      });

    // ------------ //
    // Sync Actions //
    // ------------ //

    export const { clearApiContent } = contentSlice.actions;
    export const apiContentSelector = (state: RootState) => state.content;
    export default contentSlice.reducer;
    