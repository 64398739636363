import { theme } from "utility/theme";

export const hasUpperCase = (string) => /[A-Z]/.test(string);
export const hasLowerCase = (string) => /[a-z]/.test(string);
export const hasNumber = (string) => /\d/.test(string);
export const hasThreeCharacterConsecutive = (string) => /(.)\1\1+/.test(string);
export const isValidAmount = (string) => /^\d+(\.\d{0,3})?$/.test(string);
export const base64Formatter = (string) => {
  let newString = string.replace("data:image/jpeg;base64,", "");
  newString = newString.replace("data:image/png;base64,", "");
  return newString;
};
export const NAME_REGEX =
  /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;

export const locateInitials = (string) => {
  const showedName = string?.split(" ");
  if (!showedName) {
    return "";
  }
  const firtsName = showedName[0];
  const secondName = showedName[1];
  if (secondName?.length > 0) {
    return `${firtsName[0] ?? ""}${secondName[0] ?? ""}`;
  } else {
    return firtsName[0];
  }
};
export const getRandomCardColor = () => {
  const colors = [
    theme.colors.success_main,
    theme.colors.primary_1,
    theme.colors.warning_main,
    theme.colors.secondary_1,
  ];
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return colors[randomIntFromInterval(0, colors?.length - 1)];
};

export const toCurrencyString = (
  num: number,
  decimalPositions = 2,
  currency: null | string = null,
  spacedCurrency = true
) => {
  if (num == null || isNaN(num))
    return `${currency ?? ""}${
      currency != null && spacedCurrency ? " " : ""
    }${(0)?.toFixed(decimalPositions).replace(".", ",")}`;
  return `${currency ?? ""}${currency != null && spacedCurrency ? " " : ""}${
    num?.toFixed(decimalPositions)
    .replace(".", ",")
    .replace(
      decimalPositions == 0
        ? /(\d)(?=(\d{3})+(?!\d))/g
        : /(\d)(?=(\d{3})+(?!\d)?,)/g,
      "$1."
    )}`;
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
