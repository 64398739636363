import { getRefreshToken } from "utility/storage/auth";
import {  axiosOnboardingInstance } from "./axiosInstances";

export default {
    begin: () =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/begin"),
    nickname: (nickname:string) =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/nickname",{nickname}),
    uploadDniFront: (DNIFront:string) =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/upload-dni-front",DNIFront),
    uploadDniBack: (DNIBack:string) =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/upload-dni-back",DNIBack),
    ///para que sirve selfieGesture
    selfieGesture: () =>
    axiosOnboardingInstance.get("/onboard/selfie-gesture"),
    uploadSelfie: (selfie:string) =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/upload-selfie",{selfie}),
    uploadSignature: (data:any) =>
    axiosOnboardingInstance.post("/onboard/upload-signature",data),
    getUserInfo: () =>
    axiosOnboardingInstance.get("/onboard/user-info"),
    uploadUserInfo: (data:any) =>
    axiosOnboardingInstance.patch("/onboard/user-info",data),
    getInversorQuestions: (data:any) =>
    axiosOnboardingInstance.get("/onboard/investor-questions"),
    getCityAndStateByZipcode: (data:any) =>
    axiosOnboardingInstance.get(`/onboard/postalcode/${data.zipcode}`),
    referralCheck: (referralCode:string) =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/referral",referralCode),
    inversorQuestions: (data:any) =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/investor-questions",data),
    finalize: () =>
    axiosOnboardingInstance.post<{nextStep:string}>("/onboard/finalize"),
};  
