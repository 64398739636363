import { axiosNotificationsInstance } from "./axiosInstances";

export default {
  registerUserDevice: (app: "Android" | "iOS" | "Web", token: string) =>
    axiosNotificationsInstance.post("/register_user", {
      app,
      token,
    }),
  unregisterUserDevice: (token: string) =>
    axiosNotificationsInstance.delete("/delete_user", { data: { token } }),
  getNotifications: () => axiosNotificationsInstance.get("/get_notifications"),
  markNotificationAsRead: (id?: string) =>
    axiosNotificationsInstance.put("/read_notification", {
      messageId: id,
    }),
};
