import {
  axiosCashflowInstance,
} from "./axiosInstances";

export default {
  postCashout: (data) => axiosCashflowInstance.post(`/movement/cash-out`, data),
  getBank: (data) => axiosCashflowInstance.get(`/bank/${data.account}/${data.currency}`),
  getBanks: () => axiosCashflowInstance.get(`/bank/accounts`),
  patchBank: (data) => axiosCashflowInstance.patch(`/bank/account`, data),
  deleteBank: (id) => axiosCashflowInstance.delete(`/bank/account/${id}`),
};
