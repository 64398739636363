import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./auth/authSlice";
import marketSlice from "./market/marketSlice";
import navbarSlice from "./navbar/navbarSlice";
import layoutSlice from "./layout/layoutSlice";
import onboardingSlice from "./onboarding/onboardingSlice";
import userInfoSlice from "./userInfo/userInfoSlice";
import transferSlice from "./transfer/transferSlice";
import walletSlice from "./wallet/walletSlice";
import cashflowSlice from "./cashflow/cashflowSlice";
import notificationSlice from "./notification/notificationSlice";
import contentSlice from "./content/contentSlice";

const rootReducer = combineReducers({
  auth: AuthSlice,
  userInfo: userInfoSlice,
  navbar: navbarSlice,
  market: marketSlice,
  layout: layoutSlice,
  onboarding: onboardingSlice,
  transfer: transferSlice,
  wallet: walletSlice,
  content: contentSlice,
  cashflow: cashflowSlice,
  notification: notificationSlice,
});

export default rootReducer;
