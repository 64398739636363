import React from "react";
import { isMobile } from "react-device-detect";
import qrImage from "../../../assets/img/qr-banza.svg";
import logoBanza from "../../../assets/img/logo-banza-blue.svg";
import cameraImage from "../../../assets/img/icon-camara.svg";
import android from "../../../assets/img/androidstore.svg";
import ios from "../../../assets/img/appstore.svg";
import styles from "./WebShutdownNotice.module.scss";

const WebShutdownNotice = () => {
  return (
    <div className={styles["bz-containerNotice"]}>
      <div className={styles["bz-contentNotice"]}>
        <div className={styles["bz-logoBanza"]}>
          <img src={logoBanza} alt="logo banza" />
        </div>
        {isMobile ? (
          <>
            <div className={styles["bz-descriptionContainerNotice"]}>
              <p className={styles["bz-descriptionTitle"]}>
                La versión web ya no esta disponible
              </p>
              <p className={styles["bz-description"]}>
                Pero no te preocupes, podes seguir utilizando Banza desde tu
                celular.
              </p>
            </div>
            <div className={styles["bz-storeImagesContainer"]}>
              <div className={styles["bz-androidImgContainer"]}>
                <a href="https://play.google.com/store/apps/details?id=com.banza.app&hl=es">
                  <img
                    src={android}
                    alt="Google Play"
                    className={styles["bz-androidImage"]}
                  />
                </a>
              </div>
              <div className={styles["bz-iosImgContainer"]}>
                <a href="https://apps.apple.com/ar/app/banza-inversiones-d%C3%B3lar-mep/id1660061488">
                  <img
                    src={ios}
                    alt="App Store"
                    className={styles["bz-iosImage"]}
                  />
                </a>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles["bz-descriptionContainerNotice"]}>
              <p className={styles["bz-descriptionTitle"]}>
                La versión web ya no esta disponible
              </p>
              <p className={styles["bz-description"]}>
                Pero no te preocupes, podes seguir utilizando Banza desde tu
                celular. Escaneá el QR para descargar nuestra app y seguir
                potenciando tus ahorros.
              </p>
            </div>
            <div className={styles["bz-qrContainerNotice"]}>
              <img
                src={qrImage}
                alt="QR Code"
                className={styles["bz-qrImage"]}
              />
              <button className={styles["bz-buttonCamera"]}>
                Escaneáme <img src={cameraImage} alt="camera icon" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WebShutdownNotice;
