import axios, { AxiosInstance } from "axios";
import { refreshToken } from "store/auth/authSlice";
import { getToken } from "utility/storage/auth";

import { getRefreshToken } from "../utility/storage/auth";

let store;

export const injectStore = (_store) => {
  store = _store;
};

// ------------------- //
// Annonymous Instance //
// ------------------- //

const getAnonInstance = (baseUrl: string) => {
  const anonInstance = axios.create({
    baseURL: baseUrl,
    // headers: new Headers({
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       'Access-Control-Allow-Origin': '*',
    //       "accept":"*/*"
    //     }),
    headers: {
      common: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        accept: "*/*",
      } as any,
    },
  });

  anonInstance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  anonInstance.interceptors.request.use(async (config: any) => {
    return config;
  });

  return anonInstance;
};

// ---------------------- //
// Authenticated Instance //
// ---------------------- //

export const getAuthInstance = (baseUrl: string) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });
  axiosInstance.interceptors.response.use(
    (response: any) => {
      // console.log("getAuthInstance OK");
      // logStep("request result: " + JSON.stringify(response.data));
      // ** Get token from localStorage
      const accessToken = getToken();

      // console.log("accessToken", accessToken);
      // ** If token is present add it to request's Authorization Header
      if (accessToken) {
        // ** eslint-disable-next-line no-param-reassign
        response.headers.Authorization = `${process.env.REACT_APP_TOKEN_TYPE} ${accessToken}`;
      }
      return response;
    },
    (error: any) => {
      console.log("error", error);
      const { config, response } = error;
      if (response && response.status === 401) {
        store.dispatch(refreshToken(getRefreshToken() || ""));
      }
      // Any status codes that falls outside the range of 2xx cause function to trigger
      // Do something with response error
      // handleErrorLogger("request error: " + JSON.stringify(error.response));
      return Promise.reject(error);
    }
  );
  // return axiosInstance;
  axiosInstance.interceptors.request.use(async (config: any) => {
    const accessToken = getToken();

    // console.log(accessToken,"token");

    if (accessToken)
      config.headers.Authorization = `${process.env.REACT_APP_TOKEN_TYPE} ${accessToken}`;
    return config;
  });

  return axiosInstance;
};

export const ejectInterceptor = (
  instance: AxiosInstance,
  interceptor: number
) => {
  instance.interceptors.request.eject(interceptor);
};

export const axiosAnonInstance = getAnonInstance(
  process.env.REACT_APP_BASE_URL!
);

export const axiosDataInstance = getAuthInstance(
  process.env.REACT_APP_MARKET_URL!
);
export const axiosPaymentInstance = getAuthInstance(
  process.env.REACT_APP_PAYMENT_BASE_URL!
);

export const axiosWalletInstance = getAuthInstance(
  process.env.REACT_APP_WALLET_BASE_URL!
);

export const axiosContentInstance = getAuthInstance(
  process.env.REACT_APP_CONTENT_BASE_URL!
);

export const axiosOnboardingInstance = getAuthInstance(
  process.env.REACT_APP_BASE_URL!
);

export const axiosAuthInstance = getAuthInstance(
  process.env.REACT_APP_AUTHENTICATION_URL!
);

export const axiosCashflowInstance = getAuthInstance(
  process.env.REACT_APP_CASHFLOW_URL!
);

export const axiosNotificationsInstance = getAuthInstance(
  process.env.REACT_APP_NOTIFICATIONS_URL!
);

export const axiosJsonServerInstance = getAuthInstance(
  "http://localhost:3000/"
); //TODO, mirar como conectarlo con el env
