import moment from "moment";
import { getRefreshToken } from "utility/storage/auth";
import { axiosJsonServerInstance, axiosDataInstance } from "./axiosInstances";
import { dollarMepData } from "./types";

export default {
  getInstrumentDetail: (ticker: string) =>
    axiosDataInstance.get(`/market/instruments/${ticker}`),
  getInstrumentHistory: (arg: any) =>
    axiosDataInstance.get(
      `/market/instruments/${arg.ticker}/historical?timeSpan=${arg.term}`
    ),
  getInstrumentExpenses: (arg: any) =>
    axiosDataInstance.post(`/market/orders/expenses`, arg),
  getMarketInfo: () => axiosDataInstance.get(`/market/info`),
  getUserHoldings: () => axiosDataInstance.get(`/market/account/holdings`),
  getUserAccount: () => axiosDataInstance.get(`/market/account/position`),
  getInstruments: (tag) =>
    axiosDataInstance.get(`/market/instruments/tags/${tag ? tag : ""}`),
  getTendencies: () =>
    axiosDataInstance.get(`/market/instruments/tags/Tendencias`),
  getDolarMEP: () => axiosDataInstance.get(`/market/mep/price`),
  getDolarMepNew: () => axiosDataInstance.get("/market/operations-between-tickers/prices/mep"),
  buyDolarMEP: (data: dollarMepData) => axiosDataInstance.post("/market/mep/buy", data),
  // axiosDataInstance.get(/market/mep/buy,amount)
  sellDolarMEP: (data: dollarMepData) => axiosDataInstance.post("/market/mep/sell", data),
  getDolarMEPExpenses: (data: any) =>
    axiosDataInstance.post(`/market/mep/expenses`, data),
  getDateDolarMep: (data: any) => 
  axiosDataInstance.post(`/market/mep/expenses`, data),
  // axiosDataInstance.get(/market/mep/sell,amount)
  changeNavbarState: (data: any) => data,
  buyPack: (data: any) => axiosDataInstance.post("/market/orders/packs", data),
  buyFCI: (data: any) =>
    axiosDataInstance.post("/market/ordersfdo/packs", data),
  buyFund: (data: any) => axiosDataInstance.post("/market/ordersfdo", data),
  sellFund: (data: any) => axiosDataInstance.post("/market/ordersfdo", data),
  orderSell: (data: any) => axiosDataInstance.post("/market/orders", data),
  getAllActions: () => axiosDataInstance.get("/market/instruments/equities"),
  getAllCertificates: () =>
    axiosDataInstance.get("/market/instruments/certificates"),
  getAllBonds: () => axiosDataInstance.get("/market/instruments/bonds"),
  getAllPacks: () => axiosDataInstance.get("/market/instruments/packs"),
  getAllFunds: () => axiosDataInstance.get("/market/instruments/funds"),
  getIndustriesEquities: () =>
    axiosDataInstance.get("/market/instruments/equities/industries"),
  getFavorites: () => axiosDataInstance.get("/market/instruments/favorites"),
  postFavorite: (ticker) =>
    axiosDataInstance.post(`/market/instruments/favorites/${ticker}`),
  deleteFavorite: (ticker) =>
    axiosDataInstance.delete(`/market/instruments/favorites/${ticker}`),
  searchInstruments: (search_term) =>
    axiosDataInstance.get(`/market/instruments/search/${search_term}`),
  postProfitLoss: (data) => axiosDataInstance.post(`/market/profitloss`, data),
  putProfitLoss: (data) => axiosDataInstance.put(`/market/profitloss`, data),
  deleteProfitLoss: (id) =>
    axiosDataInstance.delete(`/market/profitloss`, { data: { processId: id } }),
  getProfitLoss: (id) =>
    axiosDataInstance.get(`/market/profitloss/?processId=${id}`),
  getFundDate: (ticker: any) =>
    axiosDataInstance.get(`market/instruments/funds/${ticker}/dates`),
  getOrders: () =>
    axiosDataInstance.get(
      `market/orders?FechaDesde=${moment()
        .add(-3, "days")
        .format("YYYY-MM-DD")}&FechaHasta=${moment().format("YYYY-MM-DD")}`
    ),
  getOrdersfdo: () =>
    axiosDataInstance.get(
      `market/ordersfdo?FechaDesde=${moment()
        .add(-4, "days")
        .format("YYYY-MM-DD")}&FechaHasta=${moment().format("YYYY-MM-DD")}`
    ),
  deleteOrder: (orderId) =>
    axiosDataInstance.delete(`market/orders/${orderId}`),
  getPdfUrlOrder: (orderId) =>
    axiosDataInstance.get(`market/orders/${orderId}/ticket`),
  deleteOrderfdo: (orderId) =>
    axiosDataInstance.delete(`market/ordersfdo/${orderId}`),
  getInstrumentProfit: (ticker: string) =>
    axiosDataInstance.get(`market/instruments/profit/${ticker}`),
  getMmFciInfo: () => 
  axiosDataInstance.get("/market/info/mm_fci"),
};
