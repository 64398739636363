import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { toCurrencyString } from "utility/helpers/stringHelpers";
import type { RootState } from "../store";
import { WalletProfit, WalletState } from "./walletTypes";

export const initialState: WalletState = {
  lastMovements: undefined,
  lastMovementsByInstrument: undefined,
  walletPerformance: undefined,
  isLoadingLastMovements: undefined,
  isLoading: true,
  errorMessage: undefined,
  errorCode: undefined,
  tickerInvestmentInfo: null,
  profitWalletPortFolio: null,
  profitWalletPortFolioError: null,
  profitPortfolio: null,
};

// export const getLastMovements = createAsyncThunk(
//   "get-last-movements",
//   async (currency: string, thunkAPI: any) => {
//     try {
//       const res = await thunkAPI.extra.wallet.getLastMovements(currency);
//       return res;
//     } catch (err: any) {
//       if (!err.response) {
//         throw err;
//       }
//       return thunkAPI.rejectWithValue(err.response.data);
//     }
//   }
// );

export const getLastMovementsRange = createAsyncThunk(
  "get-Filtered-Movements",
  async (argument: any, thunkAPI: any) => {
    try {
      const { dateFrom, dateTo } = argument; // Obtener los valores de los parámetros si se proporcionan
      
      // Establecer los valores predeterminados si no se proporcionan los parámetros
      const defaultDateFrom = moment().add(-1, 'month');
      const defaultDateTo = moment();
      const finalDateFrom = dateFrom ? dateFrom : defaultDateFrom;
      const finalDateTo = dateTo ? dateTo : defaultDateTo;

      // Resto del código...
      const actions = [
          ...categoryToActionArray.investment,
          // ...categoryToActionArray.payments,
          // ...categoryToActionArray.performance,
          // ...categoryToActionArray.rounding,
          // ...categoryToActionArray.savingsGoal,
          ...categoryToActionArray.transfers,
      ];
      const currencies = ['ARS', 'USD'];
      // -------
      const data = await thunkAPI.extra.wallet.getFilteredMovements(
        null,
        finalDateFrom.startOf('day').format('YYYY-MM-DD'),
        finalDateTo.endOf('day').format('YYYY-MM-DD'),
        currencies.join(','),
        actions.join(','),
      );

      return data;

    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getMovementsByInstrument = createAsyncThunk(
  "get-movements-by-ticker",
  async (ticker: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.wallet.getMovementsByInstrument(ticker);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getWalletPerformance = createAsyncThunk(
  "get-wallet-performance",
  async (data: any, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.wallet.getWalletPerformance(
        data.actions,
        data.currency
      );
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getInfoInstrumentProfit = createAsyncThunk(
  "get-info-instrument-profit",
  async (ticker: string, thunkAPI: any) => {
    try {
      const res = await thunkAPI.extra.wallet.getInfoInstrumentProfit(ticker);
      return res;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getOneInfoInstrumentInvested = createAsyncThunk(
  "get-one-info-instrument-invested",
  async (argument: any, thunkAPI: any) => {
    try {
      const { ticker, date_start, date_end } = argument;
      const res = await thunkAPI.extra.wallet.getOneInfoInstrumentInvested(ticker, date_start, date_end);
      const data = res.data;
      return data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getWalletProfit = createAsyncThunk(
  "get-wallet-profit",
  async (argument: WalletProfit, thunkAPI: any) => {
    try {
      const {date_start, date_end} = argument;
      const res = await thunkAPI.extra.wallet.getWalletProfit(date_start, date_end);
      const data = res.data;
      return data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
      
export const getProfitProtfolio = createAsyncThunk(
  "get-profit-portfolio",
      async (argument: any, thunkAPI: any) => {
         try {
        const { date_start, date_end } = argument;
        const res = await thunkAPI.extra.wallet.getProfitPortfolio( date_start, date_end);
        const data = res.data;
         return data;
       } catch (err: any) {
           if (!err.response) {
            throw err;
        }
         return thunkAPI.rejectWithValue(err.response.data);
      }
   }
);
export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    // ------------- //
    cleanLastMovements: (state) => {
      state.errorCode = undefined;
      state.errorMessage = undefined;
      state.lastMovementsByInstrument = undefined;
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
    // [getLastMovements.fulfilled.type]: (state, action) => {
    //   state.lastMovements = mapMovements(action?.payload?.data);
    //   state.isLoadingLastMovements = false;
    //   state.errorMessage = null;
    //   state.errorCode = null;
    // },
    // [getLastMovements.pending.type]: (state, action) => {
    //   state.isLoadingLastMovements = true;
    //   state.errorMessage = null;
    //   state.errorCode = undefined;
    // },
    // [getLastMovements.rejected.type]: (state, action) => {
    //   state.isLoadingLastMovements = false;
    //   state.errorMessage = action.payload?.message;
    //   state.errorCode = action.payload.errorCode;
    // },
      [getLastMovementsRange.fulfilled.type]: (state, action) => {
        state.lastMovements = mapMovements(action?.payload?.data);
        state.isLoadingLastMovements = false;
        state.errorMessage = null;
        state.errorCode = null;
    },
      [getLastMovementsRange.pending.type]: (state, action) => {
        state.isLoadingLastMovements = true;
        state.errorMessage = null;
        state.errorCode = undefined;
    },
      [getLastMovementsRange.rejected.type]: (state, action) => {
        state.isLoadingLastMovements = false;
        state.errorMessage = action.payload?.message;
        state.errorCode = action.payload?.errorCode;
    },
      [getMovementsByInstrument.fulfilled.type]: (state, action) => {
        state.lastMovementsByInstrument = mapMovements(action?.payload?.data);
        state.isLoadingLastMovements = false;
        state.errorMessage = null;
        state.errorCode = null;
    },
      [getMovementsByInstrument.pending.type]: (state, action) => {
        state.isLoadingLastMovements = true;
        state.errorMessage = null;
        state.errorCode = undefined;
    },
      [getMovementsByInstrument.rejected.type]: (state, action) => {
        state.isLoadingLastMovements = false;
        state.errorMessage = action.payload?.message;
        state.errorCode = action.payload.errorCode;
    },
      [getWalletPerformance.fulfilled.type]: (state, action) => {
        state.walletPerformance = action?.payload?.data;
        state.isLoadingLastMovements = false;
        state.errorMessage = null;
        state.errorCode = null;
    },
     [getWalletPerformance.pending.type]: (state, action) => {
        state.walletPerformance = [];
        state.isLoadingLastMovements = true;
        state.errorMessage = null;
        state.errorCode = undefined;
    },
      [getWalletPerformance.rejected.type]: (state, action) => {
        state.walletPerformance = [];
        state.isLoadingLastMovements = false;
        state.errorMessage = action.payload?.message;
        state.errorCode = action.payload.errorCode;
    },
      [getOneInfoInstrumentInvested.fulfilled.type]: (state, action) => {
        state.tickerInvestmentInfo = action?.payload.balances;
        state.isLoading = false;
        state.errorMessage = null;
        state.errorCode = null;
    },
      [getOneInfoInstrumentInvested.pending.type]: (state, action) => {
        state.tickerInvestmentInfo = null;
        state.isLoading = true;
        state.errorMessage = null;
        state.errorCode = undefined;
    },
      [getOneInfoInstrumentInvested.rejected.type]: (state, action) => {
        state.tickerInvestmentInfo = null
        state.isLoading = false;
        state.errorMessage = action.payload?.message;
        state.errorCode = action.payload?.errorCode;
    },
      [getWalletProfit.fulfilled.type]: (state, action) => {
        state.profitWalletPortFolio = action?.payload;
        state.isLoading = false;
        state.errorMessage = null;
        state.profitWalletPortFolioError = null;
      },
      [getWalletProfit.pending.type]: (state, action) => {
        state.profitWalletPortFolio = null;
        state.isLoading = true;
        state.errorMessage = null;
        state.profitWalletPortFolioError = null;
      },
      [getWalletProfit.rejected.type]: (state, action) => {
        state.profitWalletPortFolio = null;
        state.isLoading = false;
        state.errorMessage = action.payload?.message;
        state.profitWalletPortFolioError = action.payload?.errorCode;
      },
      [getProfitProtfolio.fulfilled.type]: (state, action) => {
        state.profitPortfolio = action?.payload?.totalBalanceArs;
        state.isLoading = false;
        state.errorMessage = null;
        state.errorCode = null;
    },
      [getProfitProtfolio.pending.type]: (state, action) => {
        state.profitPortfolio = null;
        state.isLoading = true;
        state.errorMessage = null;
        state.errorCode = undefined;
      },
      [getProfitProtfolio.rejected.type]: (state, action) => {
        state.profitPortfolio = null
        state.isLoading = false;
        state.errorMessage = action.payload?.message;
        state.errorCode = action.payload?.errorCode;
    },
  },
});

const categoryToActionArray = {
  investment: [
    "OrderBuy",
    "OrderSell",
    "OrderCancel",
    "OrderTicket",
    "FundSubscription",
    "FundRescue",
    "FundCancel",
    "FundLiquidation",
    "OrderRejection",
    "FundRejection",
    "FundSubscriptionMM",
    "FundRescueMM",
    "FundCancelMM",
    "FundLiquidationMM",
    "FundRejectionMM",
    "DividendPayment",
  ],
  savingsGoal: ["NONE"],
  payments: ["CardPayment", "CardPaymentCancel", "Tax"],
  rounding: ["Rounding"],
  performance: ["Remuneration"],
  transfers: [
    "TransferIn",
    "TransferOut",
    "TransferBanza",
    "TransferRejection",
  ],
};

export const mapInvestmentExtraData = (movement: any) => {
  const isFunds = movement.extraData?.instrument_type == "InstrumentFund";

  const extraData: any = {
    id: movement.extraData?.id,
    type: movement.extraData?.instrument_type,
    name: movement.extraData?.name,
    ticket: movement.extraData?.ticket == true,
    amount: movement.extraData?.importe,
    isSelling: isFunds
      ? movement?.extraData?.cod_tp_operacion == "RE"
      : movement?.extraData?.compra_venta == "V",
    items: [
      {
        label: "Tipo de operación",
        value: isFunds
          ? movement.extraData.cod_tp_operacion == "SU"
            ? "Suscripción"
            : "Rescate"
          : movement?.extraData?.compra_venta == "V"
          ? "Vendiste"
          : "Compraste",
      },
    ],
  };

  if (isFunds) {
    extraData.class = "Clase A";
    extraData.items = [
      ...extraData.items,
      {
        label: "Cuotapartes estimadas",
        value: toCurrencyString(
          movement.extraData?.cant_cuotapartes ?? 0,
          7,
          "",
          false
        ),
      },
      {
        label: "Valor de cuotaparte estimado",
        value: toCurrencyString(
          movement.extraData?.precio ?? 0,
          7,
          movement.extraData?.currency ?? "",
          true
        ),
      },
    ];
  } else {
    extraData.items = [
      ...extraData.items,
      {
        label: "Unidades",
        value: (movement.extraData?.cantidad ?? 0).toString(),
      },
      {
        label: "Precio",
        value: toCurrencyString(
          movement.extraData?.precio ?? 0,
          2,
          movement.extraData?.currency ?? "",
          true
        ),
      },
      {
        label: "Gastos",
        value: toCurrencyString(
          movement.extraData?.gastos_estim ?? 0,
          2,
          movement.extraData?.currency ?? "",
          true
        ),
      },
      {
        label: "Plazo",
        value:
          movement.extraData?.plazo == 2
            ? "48 hs"
            : movement.extraData?.plazo == 1
            ? "24 hs"
            : "Contado inmediato",
      },
    ];
  }

  return extraData;
};

export const mapMovements = (data: any) => {
  const originalArray = data.movements as any[];
  const mappedData: any = [];
  for (let index = 0; index < originalArray.length; index++) {
    const item = originalArray[index];
    if (
      item.userVisible == true &&
      !item.detail.toLowerCase().startsWith("moneymaker")
    ) {
      let type = "payments";
      let extraData: any = null;
      let nameOverride = null;
      if (
        categoryToActionArray.investment.indexOf(item.txActionIdentifier) >= 0
      ) {
        type = "investment";
        extraData = mapInvestmentExtraData(item);
        nameOverride = extraData?.name
          ?  `${item.extraData?.instrument_type === "InstrumentFund"
          ? extraData.isSelling ? "Rescataste" : "Suscribiste"
          : extraData.isSelling ? "Vendiste" : "Compraste"} ${
              extraData?.name
            }`
          : item.detail;
      } else if (
        categoryToActionArray.performance.indexOf(item.txActionIdentifier) >= 0
      )
        type = "performance";
      else if (
        categoryToActionArray.rounding.indexOf(item.txActionIdentifier) >= 0
      )
        type = "rounding";
      else if (
        categoryToActionArray.savingsGoal.indexOf(item.txActionIdentifier) >= 0
      )
        type = "savingsGoal";
      else if (
        categoryToActionArray.transfers.indexOf(item.txActionIdentifier) >= 0
      )
        type = "transfers";

      mappedData.push({
        id: item.id,
        name: nameOverride ?? item.detail,
        type: type as "payments" | "investment" | "transfers",
        amount: extraData?.amount ?? item.amount,
        currency: item?.extraData?.currency ?? item?.currency,
        date: moment(item.createdAt, "YYYY-MM-DD[T]HH:mm:ss").toISOString(),
        dateMs: moment(item.createdAt, "YYYY-MM-DD[T]HH:mm:ss").valueOf(),
        status: !item.canceled,
        image: item.iconUrl,
        sender: item.amount < 0,
        instrumentData: {
          name: item.extraData?.name,
          ticker: item.extraData?.ticker,
          compra_venta:
            item.extraData?.instrument_type === "InstrumentFund"
              ? item.extraData?.cod_tp_operacion
              : item?.extraData?.compra_venta,
          quantity: item.extraData?.cantidad || 0,
          instrumentType: item?.extraData?.instrument_type,
          price: extraData?.amount ?? item.amount,
          expenses: item.extraData?.gastos_estim || 0,
          cuotapartes: item.extraData?.cant_cuotapartes,
          plazo: item.extraData?.plazo,
          ticket: item.extraData?.ticket,
          isSelling: extraData?.isSeling ?? item.amount >= 0,
          id: item?.extraData?.id,
        },
      });
    }
  }

  return mappedData.sort((a, b) => b.dateMs - a.dateMs);
};

export const { cleanLastMovements } = walletSlice.actions;
export default walletSlice.reducer;
