import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import {
  PrivateRoutes,
  PublicRoutes,
  ProtectedRoutes,
} from "./DashboardRoutes";
import { useAppSelector, useAppDispatch } from "store/storeHooks";
import { getAppMaintenance, getUserState, isLoggedIn } from "store/auth/authSlice";
import { RootState } from "store/store";
import {
  clearMarketStore,
  getFavorites,
  getMarketInfo,
} from "store/market/marketSlice";
import { getInfoContent } from "store/content/contentSlice";
import { getTimestampMarketInfo, getProMode } from "utility/storage/market";
import { getTimestamp } from "utility/storage/auth";
import {
  clearUserInfoStore,
  forceFTU,
  getAccount,
  getProfile,
  setProMode,
} from "store/userInfo/userInfoSlice";
import { logout } from "../store/auth/authSlice";
import { TailSpin } from "react-loader-spinner";
import {
  disableNotifications,
  getFirebaseToken,
  handleBackgroundNotifications,
  onForegroundMessage,
  requestPermission,
  stopHandlingBackgroundNotifications,
} from "utility/helpers/firebaseHelper";
import {
  getNotifications,
  registerUserDevice,
  setDeviceToken,
  setHasUnreadNotifications,
} from "store/notification/notificationSlice";
import MaintenancePage from "components/pages/MaintenancePage";
import { WebShutdownNotice } from "components/pages/WebShutdownNotice";

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const today = new Date();
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const needPersistProtectedState = useAppSelector(
    (state: RootState) => state.onboarding.needPersistProtectedState
  );
  const marketInfo = useAppSelector(
    (state: RootState) => state.market.marketInfo
  );
  const maintenance = useAppSelector(
    (state: RootState) => state.auth.maintenance
  );
  const message = useAppSelector(
    (state: RootState) => state.auth.message
  );
  const showShutdownScreen = useAppSelector((state) => state.auth.showShutdownScreen);
  const userState = useAppSelector((state: RootState) => state.auth.userState);
  const isOperational = userState === "OPERATE";

  useEffect(() => {
    dispatch(getUserState());
    dispatch(isLoggedIn(0));
    dispatch(getAppMaintenance());
  }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      (userState === "OPERATE" || userState === "MANUAL-VERIFICATION")
      ) {
        if (userState === "MANUAL-VERIFICATION") {
          dispatch(forceFTU());
        }
        const fetch = async () => {
          await dispatch(getProfile());
        };
      dispatch(getFavorites());
      dispatch(getNotifications());
      dispatch(getAccount());
      fetch();
    }
  }, [isAuthenticated, userState]);

  useEffect(() => {
    if (
      isAuthenticated &&
      getTimestamp() &&
      Number(getTimestamp()) < today?.getTime()
    ) {
      dispatch(logout(false));
      dispatch(clearMarketStore());
      dispatch(clearUserInfoStore());
    }
  }, []);

  // NOTIFICATIONS
  useEffect(() => {
    handleBackgroundNotifications(() =>
      dispatch(setHasUnreadNotifications(true))
    );

    return () => {
      stopHandlingBackgroundNotifications();
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && isOperational) {
      getFirebaseToken().then((token) => {
        if (!token) return;
        dispatch(setDeviceToken(token));
        dispatch(registerUserDevice());
        onForegroundMessage()
          .then((payload) => {
            dispatch(setHasUnreadNotifications(true));
          })
          .catch((err) => {
            /* */
          });
      });
    } else if (!isAuthenticated) {
      disableNotifications();
    }
  }, [isAuthenticated, isOperational]);
  // --------------

  useEffect(() => {
    if (
      isAuthenticated &&
      (new Date(getTimestampMarketInfo() ?? 0).getDate() < today?.getDate() ||
        marketInfo == undefined)
    ) {
      dispatch(getMarketInfo());
      dispatch(setProMode(getProMode() === "true" ? true : false));
    }
  }, [isAuthenticated]);

  if (isAuthenticated === null) {
    return null;
  }
  return (
    <React.Suspense
      fallback={
        <TailSpin
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
          height="100"
          width="100"
          color="#1f65ff"
          ariaLabel="loading"
        />
      }
    >
      <Router>
        {maintenance ? (<MaintenancePage title="¡Disculpa las molestias! El sitio web está en mantenimiento." message="Para acceder a nuestros servicios, escaneá el código QR y utilizá Banza desde tu celular. No te preocupes, podes hacer todo desde la app." />) 
        : showShutdownScreen ? (
            <WebShutdownNotice />
          ) : (isAuthenticated ? (
          (userState !== "OPERATE" && userState !== "MANUAL-VERIFICATION") ||
          needPersistProtectedState ? (
            <ProtectedRoute
              component={ProtectedRoutes}
              isAuthenticated={isAuthenticated}
            />
          ) : (
            <PrivateRoute
              component={PrivateRoutes}
              isAuthenticated={isAuthenticated}
            />
          )
        ) : (
          <PublicRoute
            component={PublicRoutes}
            isAuthenticated={isAuthenticated}
          />
        ))}
      </Router>
    </React.Suspense>
  );
};
export default AppRouter;
