import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StepType } from "@reactour/tour";
export const initialState: any = {
  errorMessage: "",
  isLoading: false,
  bgColorWhite: false,
  darkVeil: false,
  tourMode: null
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    // ------------- //
    // Sync Reducers //
    // ------------- //
    handleContentWidth: (state, action: PayloadAction<any>) => {
      state.contentWidth = action.payload;
    },
    setBgColorWhite: (state, action: PayloadAction<boolean>) => {
      state.bgColorWhite = action.payload;
      if (state.bgColorWhite)
        document.body.classList.add('bz-whiteBg');
      else
        document.body.classList.remove('bz-whiteBg');
    },
    setDarkVeil: (state, action: PayloadAction<boolean>) => {
      state.darkVeil = action.payload;
      if (state.darkVeil) {
        document.getElementById('foreground-veil')?.classList.add('bz-foreground');
      }
      else {
        document.getElementById('foreground-veil')?.classList.remove('bz-foreground');
      }
      // if(state.darkVeil)
      //   document.body.classList.add('bz-darkVeil');
      // else
      //   document.body.classList.remove('bz-darkVeil');
    },
    setTourMode: (state, action: PayloadAction<string>) => {
      state.tourMode = action.payload;
    },
  },
  extraReducers: {
    // -------------- //
    // Async Reducers //
    // -------------- //
  },
});

export const { handleContentWidth, setBgColorWhite, setDarkVeil, setTourMode } =
  layoutSlice.actions;

export const layoutSelector = (state: RootState) => state.layout;
export default layoutSlice.reducer;
