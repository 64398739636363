import React from "react";
import AppRouter from "./router/AppRouter";
import * as amplitude from '@amplitude/analytics-browser';

const BanzaApp = () => {
  // const isAuthenticated = useAppSelector(
  //   (state: RootState) => state.auth.isAuthenticated
  // );

  // const isAuthenticated = localStorage.getItem("isAuthenticated");

  // useEffect(() => {

  // }, [isAuthenticated]);
  const API_KEY = 'f46e5e2629c6e080e117cd81f4ba0e5d'
  const OPTIONAL_USER_ID = "";

  amplitude.init(API_KEY, OPTIONAL_USER_ID, {
    logLevel: amplitude.Types.LogLevel.Debug,
  });

  return (
    // <Layout />
    <AppRouter />
  );
};

export default BanzaApp;
