import "../assets/scss/variables/_variables.scss";

export const fontWeight = {
  regular: 400,
  medium: 500,
  // weightBold: 600,
};
// const colors = {
//   black: "#000",
//   bzGreen: "#23CE51",
//   // $red: #e61744;
// };

export const theme = {
  colors: {
    $black: "#030303",
    $white: "#ffffff",
    neutral_0: "#191a1b",
    neutral_1: "#474748",
    neutral_2: "#757576",
    neutral_3: "#a3a3a3",
    neutral_4: "#d1d1d1",
    neutral_5: "#f3f3f3",
    neutral_6: "#f9f9f9",
    primary_0: "#0020e2",
    primary_1: "#1f65ff",
    primary_2: "#4b83ff",
    primary_3: "#78a2ff",
    primary_4: "#a5c1ff",
    primary_5: "#d2e0ff",
    primary_6: "#f3f7ff",
    secondary_0: "#3700bb",
    secondary_1: "#6200ee",
    secondary_2: "#8133f1",
    secondary_3: "#a066f5",
    secondary_4: "#c099f8",
    secondary_5: "#c099f8",
    secondary_6: "#f7f2ff",
    terciary_0: "#ff490a",
    terciary_1: "#ff7c1f",
    terciary_2: "#ff964b",
    terciary_3: "#ffb078",
    terciary_4: "#ffcaa5",
    terciary_5: "#ffe5d2",
    terciary_6: "#fff8f3",
    error_light: "#ffe4e5",
    error_main: "#ff3e3e",
    error_dark: "#540001",
    warning_light: "#fff4de",
    warning_main: "#ffc523",
    warning_dark: "#7d4900",
    success_light: "#e1fbe7",
    success_main: "#23ce51",
    success_dark: "#005016",
    info_light: "#eaf1fb",
    info_main: "#4b83ff",
    info_dark: "#001437",
    // white: "#fff",
    // black: "#000",
    // red: "#e61744",
    // bzBlue: "#1f65ff",
    // mediumLowGrey: "#757576",
    // grey2: "#ACAEAF",
    // bzGrey: "rgba(0,0,0, 0.5)",
    // grey: "#A3A3A3",
    // lightGreen: "#23ce51",
    // blackSoft: "#030303",
    // neutralBlack: "#191A1B",
    // mediumGray: "#757576",
    // bzGreen: "#23CE51",
  },

  typography: {
    h1: {
      tag: "h1",
      fontSize: "60px",
      letterSpacing: "-0.5px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "70px",
      color: "primary_1",
    },
    h2: {
      tag: "h1",
      fontSize: "48px",
      letterSpacing: "0px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "56px",
      color: "primary_1",
    },
    h3: {
      tag: "h1",
      fontSize: "34px",
      letterSpacing: "0.25px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "40px",
      color: "primary_1",
    },
    h4: {
      tag: "h1",
      fontSize: "28px",
      letterSpacing: "0.25px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "33px",
      color: "primary_1",
    },
    h5: {
      tag: "h1",
      fontSize: "24px",
      letterSpacing: "0px",
      fontWeight: `${fontWeight.medium}`,
      margin: "0px",
      lineHeight: "28px",
      color: "primary_1",
    },
    h6: {
      tag: "h1",
      fontSize: "20px",
      letterSpacing: "0.15px",
      fontWeight: `${fontWeight.medium}`,
      margin: "0px",
      lineHeight: "23px",
      color: "primary_1",
    },
    subtitle1: {
      color: "neutral_0",
      tag: "h2",
      fontSize: "16px",
      letterSpacing: "0.15px",
      fontWeight: `${fontWeight.medium}`,
      margin: "0px",
      lineHeight: "19px",
    },
    subtitle2: {
      tag: "h2",
      fontSize: "14px",
      letterSpacing: "0.1px",
      fontWeight: `${fontWeight.medium}`,
      margin: "0px",
      lineHeight: "16px",
    },
    body1: {
      tag: "p",
      fontSize: "16px",
      letterSpacing: "0.5px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "19px",
    },
    body2: {
      tag: "p",
      fontSize: "14px",
      letterSpacing: "0.25px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "16px",
    },
    caption: {
      tag: "p",
      fontSize: "12px",
      letterSpacing: "0.4px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "14px",
      color: "neutral_2",
    },
    overline: {
      tag: "p",
      fontSize: "10px",
      letterSpacing: "1.5px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "12px",
      color: "neutral_2",
    },
    overline2: {
      tag: "p",
      fontSize: "10px",
      letterSpacing: "0.4px",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "12px",
      color: "info_dark",
    },
    inherit: {
      tag: "p",
      fontSize: "27px",
      letterSpacing: "",
      fontWeight: `${fontWeight.regular}`,
      margin: "0px",
      lineHeight: "1.6",
    },
  },
  // space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};
